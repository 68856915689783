import { getService } from './service';
// import bestContrast from 'get-best-contrast-color';
import { handleError } from './ErrorReducer';

const GET_SETTINGS = 'CONFIG/GET_SETTINGS';
const CLEAR_SETTINGS = 'CONFIG/CLEAR_SETTINGS';

// ================  ACTIONS  ======================

export const getSettings = () => {
  return async dispatch => {
    try {
      const service = getService('settings-resources');
      const settings = await service.find({ query: { type: 'settings' }});
      dispatch({ type: GET_SETTINGS, settings});
    } catch (error) {
      dispatch(handleError(error, 'Error loading settings'));
    }
  };
};

export const clearSettings = () => dispatch => {
  dispatch({ type: CLEAR_SETTINGS });
}

export const saveSettings = (type, data) => {
  const service = getService('settings-resources');
  return service.patch(type, { type: 'settings', data });
}

// ==================  REDUCERS  ===============
const defaultConfig = {
  loaded: false,
  dictionary: {
    Statuses: [],
    CallTypes: [],
    Dispositions: [],
    EventSubTypes: [],
    RequestedActions: [],
    CallMethods: [],
    PartyRelationships: [],
    PtsPersons: [],
    NamePrefixes: [],
    NameSuffixes: [],
    AddressCategories: [],
    StreetDirections: [],
    StreetTypes: [],
    Cities: [],
    States: [],
    ESN: [],
    AddressUnitTypes: [],
    UnitActions: [],
    UnitActionModifiers: [],
  },
  options: {},
};

export default function reducer(state = defaultConfig, action) {
  switch (action.type) {
    case GET_SETTINGS:
      return { ...state, ...action.settings, loaded: true };
    case CLEAR_SETTINGS:
      return { ...defaultConfig }
    default:
      return state;
  }
}
