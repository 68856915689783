import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Content from '../Content';
import RequiredCapabilities from './RequiredCapabilities';
import Recommendations from './Recommendations';
import Capabilities from './Capabilities';
import AssignCapability from './AssignCapability';
import Units from './AssignToUnits';
import AssignItems from './AssignItems';

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: '100%',
    '& button': {
      '& > span': {
        textTransform: 'none',
      },
    },
  },
  settings: {
    height: '100%',
    overflowY: 'auto',
  },
}));

function Resources() {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState('capabilities');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Content>
      <div className={classes.settings}>
        <Tabs
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="Capabilities" value="capabilities" />
          <Tab label="Assign Capability" value="assign" />
          <Tab label="Required Capabilities" value="event-types" />
          <Tab label="Settings" value="settings" />
          <Tab label="Assign To Units" value="assign-to-units" />
          <Tab label="Assign Items" value="assign-items" />
        </Tabs>
        <div role="tabpanel" className={classes.tabPanel}>
          <Box p={3}>
            {tabValue === 'event-types' && <RequiredCapabilities />}
            {tabValue === 'assign' && <AssignCapability />}
            {tabValue === 'capabilities' && <Capabilities />}
            {tabValue === 'settings' && <Recommendations />}
            {tabValue === 'assign-to-units' && <Units />}
            {tabValue === 'assign-items' && <AssignItems />}
          </Box>
        </div>
      </div>
    </Content>
  );
}
export default Resources;
