import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { findSearchProfiles, findSearchGroups, getSearchProfileGroups, 
  findSearchProfileGroups, saveSearchProfileGroups, removeProfile, findDeletedProfiles,
  restoreProfile } from '../SecFunctions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { Fills } from 'utils/formStyles';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from 'components/Tooltip';
import { showAddSecProfile, showEditSecProfile } from 'reducers/DialogsReducer';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@mui/material/Switch';
import RestoreIcon from '@mui/icons-material/Restore';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: 20
  },
  header: {
    marginBottom: 30
  }, 
  roles: {
    marginTop: 30
  },
  autoComplete: {
    maxWidth: 300,
    marginTop: 10
  },
  checkStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '5px'
  },
  item: {
    flex: '1 1 250px',
    margin: '5px'
  },
  btn: {
    textAlign: 'right', 
    marginRight: '10px'
  }
}));

function SearchProfiles(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [allProfiles, setAllProfiles] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [allProfileGroups, setAllProfileGroups] = useState([]);
  const [profile, setProfile] = useState(null);
  const [profileWithGroups, setProfileWithGroups] = useState(null);
  const [isSwitchedOn, setIsSwitchedOn] = useState(false);
  const [allDeletedProfiles, setAllDeletedProfiles] = useState([]);
  const [currentDeletedProfile, setCurrentDeletedProfile] = useState(null);

  useEffect(() => {
    fetchProfiles();
    fetchGroups();
    fetchProfileGroups();
  }, [])

  useEffect(() => {
    fetchCurrentProfileGroups();
  }, [profile])

  useEffect(() => {
    if (isSwitchedOn) fetchDeletedProfiles();
  }, [isSwitchedOn]);

  useEffect(() => {
    if (dataUpdate && (dataUpdate.type === "add-profile" || dataUpdate.type === "edit-profile")) fetchProfiles();
  }, [dataUpdate])

  const fetchProfiles = async () => {
    const result = await findSearchProfiles();
    setAllProfiles(result);
  }

  const fetchDeletedProfiles = async () => {
    const result = await findDeletedProfiles();
    setAllDeletedProfiles(result);
  }

  const fetchGroups = async () => {
    const result = await findSearchGroups();
    setAllGroups(result);
  }

  const fetchProfileGroups = async () => {
    const result = await findSearchProfileGroups();
    setAllProfileGroups(result);
  }

  const fetchCurrentProfileGroups = async () => {
    if (!profile) return;
    const groups = [];
    const currentProfileGroups = await getSearchProfileGroups(profile.ptsProfileID);
    currentProfileGroups.forEach(group => {
      groups.push(group.ptsGroupID);
    });
    const newProfile = {...profile, Groups: groups};
    setProfileWithGroups(newProfile);
  }

  const handleSaveClick = async () => {
    const existingGroups = allProfileGroups
      .filter(pg => pg.ptsProfileID === profile.ptsProfileID)
      .map(pg => pg.ptsGroupID);
    const data = {
      ProfileId: profile.ptsProfileID,
      ExistingGroups: existingGroups,
      CurrentGroups: profileWithGroups.Groups
    }
    try{
      await saveSearchProfileGroups(data);
      props.notify("Profile Roles Updated", "success")
    } catch(err) {
      props.handleError(err, 'Error Updating Roles');
    }
  }

  const disableSave = () => {
    return !Boolean(profile);
  }


  const addSecProfile = () => props.showAddSecProfile();

  const editSecProfile = () => props.showEditSecProfile(profile.ptsProfileID);

  const deleteSecProfile = async () => {
    if (!window.confirm('Are you sure you want to remove this profile?')) return;
    try {
      await removeProfile(profile.ptsProfileID);
      setProfile(null);
      fetchProfiles();
      props.notify("Profile Removed", "success");
    }
    catch(err) {
      props.handleError(err, 'Error Removing Profile');
    }
  };


  const undeleteProfile = async () => {
    if (!window.confirm('Are you sure you want to restore this profile?')) return;
    try {
      await restoreProfile(currentDeletedProfile.ptsProfileID);
      setCurrentDeletedProfile(null);
      fetchProfiles();
      fetchDeletedProfiles();
      props.notify("Profile Restored", "success");
    }
    catch(err) {
      props.handleError(err, 'Error Restoring Profile');
    }
  }


  const renderProfiles = () => {
    const handleChange = (e, newValue) => setProfile(newValue);
    return (
      <Autocomplete
        disablePortal
        options={allProfiles}
        className={classes.autoComplete}
        renderInput={(params) => <TextField {...params} label="Profiles" variant="outlined" />}
        onChange={handleChange}
        getOptionLabel={(option) => option.Label}
        renderOption={option => `${option.Label} ${option.Type ? '- ' + option.Type : ''}`}
        getOptionSelected={(option, value) => option.ptsProfileID === value.ptsProfileID}
        size="small"
        value={profile}
      />
    )
  }

  const renderGroup = (group) => {
    const handleChange = () => {
      const newGroups = [... profileWithGroups.Groups];
      const foundIdx = newGroups.indexOf(group.ptsGroupID);
      if (foundIdx === -1) {
        newGroups.push(group.ptsGroupID);
      }
      else newGroups.splice(foundIdx, 1);
      const newProfileWithGroups = {...profileWithGroups, Groups: newGroups};
      setProfileWithGroups(newProfileWithGroups);
    }
    const handleChecked = () => {
      const Groups = profileWithGroups?.Groups;
      if (!Groups) return false;
      const found = Groups.find(g => g === group.ptsGroupID);
      return Boolean(found);
    }
    return (
      <FormControlLabel 
        control={
          <Checkbox 
            checked={handleChecked()}
            onChange={handleChange}
            color="primary"
            disabled={!Boolean(profile)}
          />
        } 
        label={group.Label} 
      />
    )
  }

  const renderSaveButton = () => {
    return (
      <Button 
        onClick={handleSaveClick} 
        disabled={disableSave()}
        variant="contained" 
        className="mt-4"
        color="primary">
        <SaveIcon className="mr-2" /> Save 
      </Button> 
    )
  }

  const renderDeletedList = () => {
    const handleChange = (e, newValue) => setCurrentDeletedProfile(newValue);
    return (
      <Autocomplete
        disablePortal
        options={allDeletedProfiles}
        className={classes.autoComplete}
        renderInput={(params) => <TextField {...params} label="Deleted Profiles" variant="outlined" />}
        onChange={handleChange}
        getOptionLabel={(option) => option.Label}
        renderOption={option => `${option.Label} ${option.Type ? - option.Type : ''}`}
        getOptionSelected={(option, value) => option.ptsProfileID === value.ptsProfileID}
        size="small"
        value={currentDeletedProfile}
      />
    )
  }

  const renderDeletedSwitch = () => {
    const handleChange = () => setIsSwitchedOn(!isSwitchedOn);
    return (
      <FormControlLabel 
        control={
          <Switch checked={isSwitchedOn} onChange={handleChange}/>
        } 
        label="Show Deleted Profiles" 
        className="mb-4"
      />
    )
  }
  
  return (
    <Content>
      <div className={classes.wrapper}>
        <h4 className={classes.header}> Search Profiles </h4>
        {renderDeletedSwitch()}
        {isSwitchedOn && renderDeletedList()}
        {renderProfiles()}
        <div className={classes.btn}>
          <Tooltip title="Add New Profile" onClick={addSecProfile} >
            <Fab size="small" color="secondary">
              <AddIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Edit Profile" className="ml-2">
            <span>
              <Fab size="small" color="secondary" onClick={editSecProfile} disabled={!profile}>
                <EditIcon />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip title="Delete Profile" className="ml-2">
            <span>
              <Fab size="small" color="secondary" onClick={deleteSecProfile} disabled={!profile}>
                <DeleteIcon />
              </Fab>
            </span>
          </Tooltip>
          { isSwitchedOn && currentDeletedProfile && <Tooltip title="Restore" className="ml-2">
            <span>
              <Fab size="small" color="secondary" onClick={undeleteProfile}>
                <RestoreIcon />
              </Fab>
            </span>
          </Tooltip>}
        </div>
        <div className={classes.roles}>
          <h6>Roles</h6>
          <div className={classes.checkStyle}>
            {allGroups.map(group => (
              <span key={group.ptsGroupID} className={classes.item}>
                {renderGroup(group)}  
              </span>
            ))}
            <Fills className={classes.item}/>
          </div>
        </div>
        {renderSaveButton()}
      </div>
    </Content>
  );
}

const mapStateToProps = (state) => {
  return {
    dataUpdate: state.dataUpdate
  }
}

export default connect(mapStateToProps, { handleError, notify, showAddSecProfile, showEditSecProfile, })(SearchProfiles);
