import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import PersonSearchSimple from '../../components/PersonSearchSimple';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomAutocomplete from '../../components/CustomAutocomplete1';
import PhoneTextField from '../../components/PhoneTextField';
import EmailTextField from '../../components/EmailTextField';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '../../components/Dialog';
import { getRecipient, saveRecipient, updateRecipients } from '../../reducers/PagingReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { closeEditPageRecipient } from '../../reducers/DialogsReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';

function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 8px`,
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    width: 800,
    minHeight: 450,
    maxWidth: '100%',
    margin: '0 auto',
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    '& h4': {
      margin: '0.5em 0',
    },
  },
  textField: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  w100x200: {
    ...getFormStyle(100, 200),
  },
  w170x400: {
    ...getFormStyle(170, 400),
  },
  w200x300: {
    ...getFormStyle(200, 300),
  },
  w120x180: {
    ...getFormStyle(120, 180),
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 8px`,
  },
  requiredInfo: {
    fontSize: '0.75rem',
    marginBottom: 0,
  },
  addressFilter: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  checkbox: {
    margin: `-${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  actions2: {
    '& button': {
      marginLeft: theme.spacing(1),
      opacity: 0,
      transition: 'opacity 0.3s',
    },
  },
  row: {
    '&:hover': {
      '& button': {
        opacity: 1,
      },
    },
  },
}));

const headCells = [
  { id: 'ContactMethod', numeric: false, disablePadding: false, label: 'Contact Method' },
  { id: 'MethodType',   numeric: false, disablePadding: false, label: 'Method Type'    },
  { id: 'Info', numeric: false, disablePadding: false, label: 'Info' },
];

const contactMethods = [ 'Email', 'Phone' ];

function ContactTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell align="center">Default</TableCell>
        <TableCell align="center">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

function RecipientEdit(props) {
  const classes = useStyles();
  const { dictionary, ptsRecipientID } = props;
  const [data, setData] = useState(null);
  const [edited, setEdited] = useState(false);
  const [Recipient, setRecipient] = useState('');
  const [ptsPersonID, setPtsPersonID] = useState(null);
  // const [ROWGUID, setROWGUID] = useState(null);
  const [IsDisabled, setIsDisabled] = useState(null);
  const [ContactInfo, setContactInfo] = useState([]);
  const [ContactMethod, setContactMethod] = useState(null);
  const [MethodType, setMethodType] = useState(null);
  const [PhoneNo, setPhoneNo] = useState('');
  const [PhoneNoValid, setPhoneNoValid] = useState();
  const [Email, setEmail] = useState('');
  const [EmailValid, setEmailValid] = useState(false);
  const [IsDefault, setIsDefault] = useState(true);
  const [editedIndex, setEditedIndex] = useState(-1);
  const [editedValid, setEditedValid] = useState(false);
  const [valid, setValid] = useState(false);
  const [disableDefault, setDisableDefault] = useState(false);

  useEffect(() => {
    if (ptsRecipientID && ptsRecipientID !== true) {
      loadRecipient();
    } else {
      setRecipient('');
      setIsDisabled(false);
      setContactInfo([]);
    }
    // eslint-disable-next-line
  }, [ptsRecipientID]);

  useEffect(() => {
    if (!data) return;
    setRecipient(data.Recipient);
    setPtsPersonID(data.ptsPersonID);
    setIsDisabled(data.IsDisabled);
    setContactInfo(data.ContactInfo);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    validateContact();
    // eslint-disable-next-line
  }, [Email, PhoneNo, MethodType, ContactMethod, IsDefault]);

  useEffect(() => {
    validate();
    // eslint-disable-next-line
  }, [ContactInfo, Recipient, IsDisabled, ptsPersonID]);

  useEffect(() => {
    if (!ContactMethod || !edited) return;
    const Info = ContactInfo.filter(i => i.ContactMethod === ContactMethod);
    let isDefault;
    if (editedIndex !== -1){
      const contact = ContactInfo[editedIndex];
      isDefault = contact.IsDefault && contact.ContactMethod === ContactMethod;
    } else {
      isDefault = false;
    }
    const defaultExists = Info.reduce((r, c) => c.IsDefault ? true : r, false);
    let disableDefault = defaultExists;
    if (isDefault) disableDefault = false;
    setDisableDefault(disableDefault);
    if (disableDefault) setIsDefault(false);
    // eslint-disable-next-line
  }, [ContactMethod, edited]);

  const validate = () => {
    Boolean(Recipient) !== valid && setValid(Boolean(Recipient));
  }

  const loadRecipient = () => {
    props.showSpinner()
    getRecipient(ptsRecipientID)
      .then(result => setData(result))
      .catch(err =>  props.handleError(err, 'Error, Recipient data not loaded.'))
      .finally(() => props.hideSpinner());
  }

  const validateContact = () => {
    let valid = true;
    if (!ContactMethod) valid = false;
    if (!MethodType) valid = false;
    if (ContactMethod === 'Email' && !EmailValid) valid = false;
    if (ContactMethod === 'Phone' && !PhoneNoValid) valid = false;
    if (editedValid !== valid) setEditedValid(valid);
  }


  const handleRecipientChange = ev => {
    const value = ev.target.value;
    if (value.length > 300) return;
    setRecipient(value);
  }

  const handlePersonChange = person => {
    if (person) {
      setRecipient(person.FullName);
      setPtsPersonID(person.ptsPersonID);
      setContactInfo(person.ContactInfo);
    } else {
      setRecipient('');
      setPtsPersonID(null);
      setContactInfo([]);
    }
  }

  const renderRecipientForm = () => {
    return (
      <div className={classes.form}>
        <PersonSearchSimple
          className={classes.w100pr}
          onPersonChange={handlePersonChange}
          disabled={edited}
        />
        <TextField
          className={clsx(classes.textField, classes.w100pr)}
          label="Recipient"
          value={Recipient}
          onChange={handleRecipientChange}
          variant="outlined"
          size="small"
          disabled={edited}
        />
        <FormControlLabel
          className={clsx(classes.checkbox, classes.w120x180)}
          control={<Checkbox
            checked={!IsDisabled}
            onChange={ev => setIsDisabled(!IsDisabled)}
            className={classes.checkbox}
          />}
          label="Active"
          disabled={edited}
        />
      </div>
    );
  }

  const editContact = (idx) => {
    const contact = ContactInfo[idx];
    setContactMethod(contact.ContactMethod);
    setMethodType(contact.MethodType);
    setIsDefault(contact.IsDefault);
    setEdited(true);
    setEditedIndex(idx);
    if (contact.ContactMethod === 'Email') {
      setEmail(contact.Info);
      setPhoneNo('');
    } else {
      setEmail('');
      setPhoneNo(contact.Info);
    }
  }

  const deleteContact = (idx) => {
    if (window.confirm('Are you sure you want to delete the contact this method?')) {
      const newContactInfo = [...ContactInfo];
      newContactInfo.splice(idx, 1);
      setContactInfo(newContactInfo);
    }
  }

  const closeEditContact = () => {
    setEdited(false);
  }

  const addContact = () => {
    const IsDefault = ContactInfo.reduce((res, c) => c.IsDefault ? false : res, true);
    setEdited(true);
    setContactMethod(null);
    setMethodType(null);
    setEmail('');
    setPhoneNo('');
    setEditedIndex(-1);
    setIsDefault(IsDefault);
  }

  const saveContact = () => {
    if (editedIndex === -1) {
      const newContact = {
        ContactMethod,
        MethodType,
        Info: ContactMethod === 'Email' ? Email : PhoneNo,
        IsDefault: IsDefault,
      }
      setContactInfo([...ContactInfo, newContact]);
    } else {
      const newContact = ContactInfo[editedIndex];
      newContact.ContactMethod = ContactMethod;
      newContact.MethodType = MethodType;
      newContact.Info = ContactMethod === 'Email' ? Email : PhoneNo;
      newContact.IsDefault = IsDefault;
      const newContactInfo = [...ContactInfo];
      newContactInfo.splice(editedIndex, 1, newContact);
      setContactInfo(newContactInfo);
    }
    closeEditContact();
  }

  const save = () => {
    props.showSpinner();
    const recipient = {
      IsDisabled,
      ContactInfo,
      ptsPersonID,
      Recipient,
      ptsRecipientID: ptsRecipientID !== true ? ptsRecipientID : null
    }
    saveRecipient(recipient)
    .then(() => {
      props.updateRecipients();
      props.closeEditPageRecipient();
    })
    .catch(err => props.handleError(err, 'Unable to save recipient data'))
    .finally(() => props.hideSpinner());
  }

  const renderContactTable = () => {
    return (
      <>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
        >
          <ContactTableHead
            classes={classes}
            rowCount={ContactInfo.length}
          />
          <TableBody>
            {ContactInfo.map((row, index) => {
                return (
                  <TableRow
                    hover
                    key={index}
                    className={classes.row}
                  >
                    <TableCell align="left">{row.ContactMethod}</TableCell>
                    <TableCell align="center">{row.MethodType}</TableCell>
                    <TableCell align="left">{row.Info}</TableCell>
                    <TableCell align="center">{row.IsDefault ? <CheckIcon /> : null}</TableCell>
                    <TableCell align="right" className={classes.actions2}>
                      <IconButton onClick={() => editContact(index)} disabled={edited}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => deleteContact(index)} disabled={edited}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
    );
  }

  const renderActionHeader = () => {
    return (
      <div className={classes.header}>
        <h4>Recipients</h4>
        <div className={classes.actions}>
          {!edited && <Fab size="small" color="secondary" onClick={addContact}>
            <AddIcon />
          </Fab>}
          {edited && <Fab size="small" color="secondary" onClick={closeEditContact}>
            <CloseIcon />
          </Fab>}
          {edited && <Fab size="small" color="secondary" onClick={saveContact} disabled={!editedValid}>
            <SaveIcon />
          </Fab>}
        </div>
      </div>
    );
  }

  const renderEditContactForm = () => {
    return (
      <div className={classes.form}>

        <Autocomplete
          options={contactMethods}
          value={ContactMethod}
          onChange={(event, newValue) => setContactMethod(newValue)}
          className={classes.w170x400}
          renderInput={(params) => <TextField
            {...params}
            label="Contact Method*"
            variant="outlined"
          />}
          size="small"
        />

        <CustomAutocomplete
          label="Method Type*"
          options={dictionary.ContactMethodTypes}
          value={MethodType}
          onChange={newValue => setMethodType(newValue)}
          className={classes.w170x400}
          size="small"
        />

        {ContactMethod === 'Phone' && <PhoneTextField
          label="Phone*"
          className={classes.w100pr}
          variant="outlined"
          size="small"
          onChange={val =>setPhoneNo(val)}
          value={PhoneNo}
          isValid={isValid => isValid !== PhoneNoValid && setPhoneNoValid(isValid)}
          short
        />}

        {ContactMethod === 'Email' && <EmailTextField 
          label="Email*"
          className={classes.w100pr}
          variant="outlined"
          size="small"
          onChange={val=>setEmail(val)}
          value={Email}
          isValid={isValid => isValid !== EmailValid && setEmailValid(isValid)}
        />}
        {Boolean(ContactMethod) && <FormControlLabel
          className={clsx(classes.checkbox, classes.w120x180)}
          control={<Checkbox
            checked={IsDefault}
            onChange={ev => setIsDefault(!IsDefault)}
            className={classes.checkbox}
            disabled={disableDefault}
          />}
          label="Default"
        />}

      </div>
    );
  }

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={save}
          disabled={!valid || edited}
        ><SaveIcon /> Save</Button>
        <Button onClick={props.closeEditPageRecipient} color="primary" size="small">
          <CloseIcon /> Close
        </Button>
      </div>
    );
  }

  return (
    <Dialog
      open={true}
      onClose={props.closeEditPageRecipient}
      title={edited && edited.ROWGUID ? 'Edit Recipient' : 'Add Recipient'}
      actions={renderActions()}
      toolbar
    >
    <div className={classes.container}>
      {renderRecipientForm()}
      {renderActionHeader()}
      {edited && renderEditContactForm()}
      {renderContactTable()}
    </div>
  </Dialog>
  );
}

const mapStateToProps = (state) => {
  return { 
    dictionary: state.dictionary,
  }
}

export default connect(mapStateToProps, { 
  updateRecipients, showSpinner, hideSpinner, closeEditPageRecipient, handleError
})(RecipientEdit);
