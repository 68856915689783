import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import Autocomplete2 from 'components/Autocomplete2';
import TextField2 from 'components/TextField2';
import { notify } from 'reducers/NotifierReducer';
import formStyles, { Row, gridStyle } from 'utils/formStyles';
import { findSecComponents, createSecComponent } from '../SecFunctions';
import { handleError } from 'reducers/ErrorReducer';
import Markdown from 'components/Markdown';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  header: {
    marginLeft: 8,
  },
  pageWrap: {
    width: 600,
    minHeight: 300,
  },
  item: gridStyle('200', '100%'),
}));

const options = [
  { Code: 'Applications', Type: 'Modules', tabValue: 'applications' },
  { Code: 'Global - Full Permissions', Type: 'Core', tabValue: 'global' },
  { Code: 'Global - Additional Privileges', Type: 'Core.bool', tabValue: 'global' },
  { Code: 'CAD - Full Permissions', Type: 'CAD', tabValue: 'cad' },
  { Code: 'CAD - Additional Privileges', Type: 'CAD.bool', tabValue: 'cad' },
  { Code: 'OMS - Full Permissions', Type: 'OMS', tabValue: 'oms' },
  { Code: 'OMS - Additional Privileges', Type: 'OMS.bool', tabValue: 'oms' },
  { Code: 'RMS - Full Permissions', Type: 'RMS', tabValue: 'rms' },
  { Code: 'RMS - Additional Privileges', Type: 'RMS.bool', tabValue: 'rms' },
];

function ExampleWindow(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [option, setOption] = useState(null);
  const [components, setComponents] = useState([]);
  const [labels, setLabels] = useState([]);
  const [label, setLabel] = useState('');
  const [labelError, setLabelError] = useState(null);

  useEffect(() => {
    getSecComponents();
    const option = options.find((opt) => opt.tabValue === props.tabValue);
    if (!option) {
      return dispatch(notify('Permissions configuration error', 'error'));
    }
    setOption(option);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!components.length || !option) return;
    const labels = components
      .filter((c) => c.Type === option.Type)
      .map((c) => c.Label.toLowerCase());
    setLabels(labels);
    setLabel('');
    // eslint-disable-next-line
  }, [option, components]);

  const getSecComponents = async () => {
    try {
      const components = await findSecComponents();
      setComponents(components);
    } catch (err) {
      dispatch(handleError(err));
    }
  }

  const close = () => {
    props.close();
  };

  const create = async () => {
    const data = {
      Label: label,
      Type: option.Type
    };
    try {
      await createSecComponent(data);
      dispatch(notify("New Component Created", "success"));
      getSecComponents();
    } catch (err) {
      dispatch(handleError(err));
    }
  }

  const getSQL = () => {
    if (!option || !label) return '```bash\nComplete the form first\n\n```';
    return '```sql\n-- insert_secComponent_' +
      `${option.Type}_${label.replace(/\s+/g, "_")}.sql\n\nINSERT INTO secComponent (Label, Type)\n` +
      `VALUES('${label}', '${option.Type}')` + '\n```';
  }

  const renderLabel = () => {
    const onChange = (ev, val) => {
      setLabel(val);
      if (labels.indexOf(val.toLowerCase()) !== -1) {
        setLabelError('The value is already in use');
      } else {
        setLabelError(null);
      }
    }
    return (
      <TextField2
        className={classes.item}
        label="Label"
        value={label}
        onChange={onChange}
        error={labelError}
      />
    );
  }

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          onClick={create}
          disabled={!label || labelError !== null || !option}
        >
          <SaveIcon /> Create Permission
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog toolbar onClose={close} title="Add Permission" actions={renderActions()} maxWidth="xl">
      <div className={classes.pageWrap}>
        <Row>
          <Autocomplete2
            className={classes.item}
            options={options}
            value={option}
            onChange={(ev, val) => setOption(val)}
            compact
          />
          {renderLabel()}
        </Row>
        <div>Generated SQL:</div>
        <Markdown code={getSQL()} />
      </div>
    </Dialog>
  );
}

export default ExampleWindow;
