import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getGroupComponentPermission, findSecComponents, saveCodePermissions } from '../SecFunctions';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const useStyles = makeStyles((theme) => ({
  autoComplete: {
    maxWidth: 300,
    marginBottom: 10
  },
  list: {
    width: '100%',
    height: 400,
  },
  saveBtn: {
    marginRight: 10,
    marginBottom: 20,
    textAlign: 'right'
  }
}));


function Row(props) {
  const { row, allRows, setAllRows } = props;
  const [open, setOpen] = React.useState(false);

  const handleCoreCheckChange = (permission, shift, ptsComponentID) => {
    if (permission === -1) permission = 0;
    const mask = 1 << shift;
    permission ^= mask;
    const rowsClone = [...allRows];
    for (const parent of rowsClone) {
      if (parent.ptsComponentID === row.ptsComponentID) {
        for (const child of parent.Children) {
          if (child.ptsComponentID === ptsComponentID) {
            child.Permission = permission;
            break;
          }
        }
        break;
      }
    }
    setAllRows(rowsClone);
  }

  const isCoreChecked = (permission, shift) => {
    if (permission === -1) return false;
    const mask = 1 << shift;
    return (permission & mask) !== 0;
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.Label}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Components</TableCell>
                    <TableCell align="center">Read</TableCell>
                    <TableCell align="center">Create</TableCell>
                    <TableCell align="center">Edit</TableCell>
                    <TableCell align="center">Delete</TableCell>
                    <TableCell align="center">View Deleted</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.Children.map((c) => (
                    <TableRow key={c.ptsComponentID}>
                      <TableCell component="th" scope="row">
                        {c.Label}
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Checkbox 
                            checked={isCoreChecked(c.Permission, 0)}
                            onChange={() => handleCoreCheckChange(c.Permission, 0, c.ptsComponentID)}
                            color="primary"
                          />
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Checkbox 
                            checked={isCoreChecked(c.Permission, 1)}
                            onChange={() => handleCoreCheckChange(c.Permission, 1, c.ptsComponentID)}
                            color="primary"
                          />
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Checkbox 
                            checked={isCoreChecked(c.Permission, 2)}
                            onChange={() => handleCoreCheckChange(c.Permission, 2, c.ptsComponentID)}
                            color="primary"
                          />
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Checkbox 
                            checked={isCoreChecked(c.Permission, 3)}
                            onChange={() => handleCoreCheckChange(c.Permission, 3, c.ptsComponentID)}
                            color="primary"
                          />
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          <Checkbox 
                            checked={isCoreChecked(c.Permission, 7)}
                            onChange={() => handleCoreCheckChange(c.Permission, 7, c.ptsComponentID)}
                            color="primary"
                          />
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


function SecurityCodes(props) {
  const classes = useStyles();
  const { role } = props;

  const [allRows, setAllRows] = useState([]);

  useEffect(() => {
    fetchData();
  }, [role]);

  const fetchData = async () => {
    props.showSpinner();
    const allComponents = await findSecComponents();
    const parents = allComponents.filter(c => c.Type === 'ParentCode');
    for (const parent of parents) {
      const children = allComponents.filter(c => c.Type === `${parent.Label}Code`);
      const withPermissions = await attachPermissions(children);
      parent.Children = withPermissions;
    }
    setAllRows(processData(parents));
    props.hideSpinner();
  }

  const attachPermissions = async components => {
    for (const c of components) {
      const result = await getGroupComponentPermission(role.ptsGroupID, c.ptsComponentID);
      c.Permission = result.length > 0 ? result[0].Permissions : -1;
    }
    return components;
  }


  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.ptsComponentID,
      };
    });
  };


  const handleSaveClick = async () => {
    const data = {
      Rows: allRows,
      ptsGroupID: role.ptsGroupID
    }
    try {
      await saveCodePermissions(data);
      props.notify("Code Permissions Updated", "success");
    } catch(err) {
      props.handleError(err, 'Error Updating Code Permissions');
    }
  }


  const renderSaveButton = () => {
    return (
      <div className={classes.saveBtn}>
        <Button 
          onClick={handleSaveClick} 
          variant="contained"
          color="primary">
          <SaveIcon className="mr-2" /> Save 
        </Button> 
      </div>
    )
  }


  const renderCollapsibleTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" size='small'>
          <TableHead>
            <TableRow>
              <TableCell style={{width: '50px'}}/>
              <TableCell align="left">Components</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allRows.map((row) => (
              <Row key={row.ptsComponentID} row={row} allRows={allRows} setAllRows={setAllRows}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }


  return (
    <>
      {renderSaveButton()}
      {renderCollapsibleTable()}
    </>
  )


}


export default connect(null, { handleError, notify, showSpinner, hideSpinner, })(SecurityCodes);
