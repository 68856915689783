import { formatDBDate } from 'reducers/TimeReducer';

export const formatCommonCodes = values => ({
  Code: values.Code,
  Description: values.Description || values.Code,
  IsDefault: values.IsDefault,
  IsActive: values.IsActive,
  IsDeleted: false,
  Flags: values.Flags || null,
  Category: values.Category,
  Code2: values.Code2,
  Code3: values.Code3,
  Code4: values.Code4,
  Description2: values.Description2 || values.Code2,
  Description3: values.Description3 || values.Code3,
  Description4: values.Description4 || values.Code4,
});

export const formatCodeZones = values => ({
  ZoneCode: values.ZoneCode,
  Description: values.Description || values.Code,
  IsDefault: values.IsDefault,
  IsActive: values.IsActive,
  IsDeleted: false,
  Flags: values.Flags || null,
  Category: values.Category,
  Code2: values.Code2,
  Code3: values.Code3,
  Code4: values.Code4,
  Description2: values.Description2 || values.Code2,
  Description3: values.Description3 || values.Code3,
  Description4: values.Description4 || values.Code4,
});

export const formatTagTypes = values => ({
  Code: values.Code,
  Description: values.Description || values.Code,
  IsDefault: values.IsDefault,
  IsActive: values.IsActive,
  IsDeleted: false,
  Flags: values.Flags || null,
  Category: values.Category,
  Code2: values.Code2,
  Code3: values.Code3,
  Code4: values.Code4,
  Description2: values.Description2 || values.Code2,
  Description3: values.Description3 || values.Code3,
  Description4: values.Description4 || values.Code4,
  Priority: values.Priority,
  Parent: values.Parent,
  Icon: values.Icon,
});
export const formatStatuteCodes = (values, username) => ({
  Code: values.Code || '',
  Section: values.Section || '',
  Description: values.Description || '',
  Category: values.Category || '',
  RSA: values.RSA || '',
  EnactmentDate: formatDBDate(values.EnactmentDate) || '',
  Jurisdiction: values.Jurisdiction || '',
  Keyword: values.Keyword || '',
  Level: values.Level || '',
  RepealDate: formatDBDate(values.RepealDate) || '',
  Status: values.Status || '',
  AffidavitText: values.AffidavitText || '',
  BondAmount: Number(values.BondAmount) || 0,
  Severity: values.Severity || '',
  IsActive: values.IsActive || 0,
  MustAppear: values.MustAppear || 0,
  DNASampleRequired: values.DNASampleRequired || 0,
  Notes: values.Notes || '',
  IsDeleted: 0,
  ptsFBICodeID: values.ptsFBICodeID || null,
  CreatedBy: username,
  UpdatedBy: username,
});
export const formatPartyRelationShip = values => ({
  Code: values.Code,
  Description: values.Description || values.Code,
  IsDefault: values.IsDefault,
  IsActive: values.IsActive,
  Category: values.Category,
  Code2: values.Code2,
  Code3: values.Code3,
  Code4: values.Code4,
  Description2: values.Description2 || values.Code2,
  Description3: values.Description3 || values.Code3,
  Description4: values.Description4 || values.Code4,
  Entities: values.Entities,
  EmployeeRelated: values.EmployeeRelated,
  Order: Number(values.Order) || null,
  IsDeleted: false,
});

export const formatCities = values => ({
  CityDescription: values.CityDescription,
  IsDefault: values.IsDefault,
  IsActive: values.IsActive,
  Category: values.Category,
  Description2: values.Description2,
  Description3: values.Description3,
  IsDeleted: false,
});

export const formatCodeDispositions = values => ({
  AgencyID: values.AgencyID,
  ...formatCommonCodes(values),
});

export const formatCodeUnitActions = values => ({
  WaitTime: values.WaitTime,
  Color: values.Color,
  ...formatCommonCodes(values),
});

export const formatCodeZonesTypes = values => ({
  AgencyID: values.AgencyID,
  Zone1Type: values.Zone1Type,
  Zone2Type: values.Zone2Type,
  Zone3Type: values.Zone3Type,
  Zone4Type: values.Zone4Type,
  Zone5Type: values.Zone5Type,
  IsDefault: values.IsDefault,
  IsActive: values.IsActive,
  IsDeleted: false,
  Category: values.Category,
  Code2: values.Code2,
  Code3: values.Code3,
  Code4: values.Code4,
  Description2: values.Description2 || values.Code2,
  Description3: values.Description3 || values.Code3,
  Description4: values.Description4 || values.Code4,
});

export const formatCodeVehicleMakeModels = values => ({
  MakeCode: values.MakeCode,
  ModelCode: values.ModelCode,
  MakeDescription: values.MakeDescription,
  ModelDescription: values.ModelDescription,
  ...formatCommonCodes(values),
});

export const formatIncidentEventTypeCodes = values => ({
  Code: values.Code,
  Description: values.Description || values.Code,
  IsDefault: values.IsDefault,
  IsActive: values.IsActive,
  IsDeleted: false,
  Flags: values.Flags || null,
  Category: values.Category,
  Code2: values.Code2,
  Code3: values.Code3,
  Code4: values.Code4,
  Description2: values.Description2 || values.Code2,
  Description3: values.Description3 || values.Code3,
  Description4: values.Description4 || values.Code4,
  Icon: values.Icon,
});
