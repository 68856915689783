import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Checkbox,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Tooltip,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import MuiTableCell from '@material-ui/core/TableCell';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import AddButton from './AddButton';
import Content from '../Content';
import Autocomplete2 from '../../components/Autocomplete2';

import { gridStyle } from 'utils/formStyles';

import { getService } from '../../reducers/service';
import { findSearchGroups } from 'Settings/Security/SecFunctions';

const useDialogStyles = makeStyles(() => ({
  workflowDialogBox: {
    maxWidth: '100%',
    minHeight: '150px',
  },
  dialogFieldsetWrapperFilter: {
    border: '1px solid #1e8dfa',
    borderRadius: '10px',
    padding: '15px 13px 5px',
    height: '90%',
    width: '100%',
  },
  fieldsetTitleFilter: {
    position: 'absolute',
    marginTop: '-25px',
    color: 'white',
    background: '#1e8dfa',
    borderRadius: '10px',
    padding: '2px 10px',
  },
  item: {
    ...gridStyle(200, 800),
    margin: 0,
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    padding: '4px 8px',
  },
})(MuiTableCell);

function AddEditDialog(props) {
  const {
    workflowDialog,
    closeWorkflowDialog,
    workFlowDialogType,
    selectedWorkflow,
    allWorkflows,
    fetchedSteps,
    allRoles,
    agencies,
  } = props;

  const [roles, setRoles] = useState([]);
  const classes = useDialogStyles();
  const workflowService = getService('ptswflows');
  const workflowStepService = getService('ptswflowsteps');
  const workflowRoleService = getService('ptswflowroles');
  const workflowStepRolesService = getService('ptswflowsteproles');
  const [workflow, setWorkflow] = useState({});
  const [steps, setSteps] = useState([]);
  const [permissionsDialog, setPermissionsDialog] = useState(false);
  const [stepsDialog, setStepsDialog] = useState(false);
  const [workflowStep, setWorkflowStep] = useState({});
  const [reload, setReload] = useState(false);
  const [currentRole, setCurrentRole] = useState({});
  const [workflowRoles, setWorkflowRoles] = useState([]);

  const closeStepsDialog = () => {
    setStepsDialog(false);
  };

  const closePermissionsDialog = () => {
    setPermissionsDialog(false);
  };

  const handleWorkflowChange = (name, value) => {
    setWorkflow({
      ...workflow,
      [name]: value,
    });
  };

  const handleStepChange = (name, value) => {
    setWorkflowStep({
      ...workflowStep,
      ptsWFlowID: selectedWorkflow?.ptsWFlowID,
      [name]: value,
    });
  };

  const handleStepRoleChange = (name, value, id) => {
    let updatedStepRoles = workflowStep?.StepRoles;
    let stepRoleIndex = updatedStepRoles?.findIndex(stepsRole => stepsRole.RoleID === id);
    let changedRole = updatedStepRoles[stepRoleIndex];
    updatedStepRoles[stepRoleIndex] = { ...changedRole, [name]: value };

    setWorkflowStep({
      ...workflowStep,
      ptsWFlowID: selectedWorkflow?.ptsWFlowID,
      StepRoles: updatedStepRoles,
    });
  };

  const handleRoleChange = (name, value) => {
    setCurrentRole({
      ...currentRole,
      ptsWFlowID: selectedWorkflow?.ptsWFlowID,
      [name]: value,
    });
  };

  const handleClickAddPermissions = () => {
    let tempId = Math.floor(Math.random() * 101);
    setCurrentRole({ id: tempId });
    setPermissionsDialog(true);
  };

  const handleClickAddSteps = () => {
    let tempId = Math.floor(Math.random() * 101);
    setWorkflowStep({ id: tempId, StepRoles: [...workflowRoles] });
    setStepsDialog(true);
  };

  const handleWorkflowStepEditClick = step => {
    setWorkflowStep(step);
    setStepsDialog(true);
  };

  const handleWorkflowRoleEditClick = role => {
    setCurrentRole(role);
    setPermissionsDialog(true);
  };

  const handleWorkflowRoleDeleteClick = (wFlowRoleId, roleId, tempId) => {
    if (wFlowRoleId) {
      workflowRoleService
        .remove(wFlowRoleId)
        .then(() => {
          let previousWorkflowRoles = workflowRoles;
          let updatedWorkflowRoles = previousWorkflowRoles.filter(
            p => p.ptsWFlowRoleID !== wFlowRoleId
          );
          setWorkflowRoles(updatedWorkflowRoles);

          let updatedSteps = steps?.map((step, index) => {
            let initialStepRoles = step?.StepRoles;
            let updatedStepRoles = [];

            for (let role of initialStepRoles) {
              if (role.RoleID === roleId) {
                let stepRoleId = role?.ptsWFlowStepRoleID;
                workflowStepRolesService.remove(stepRoleId).catch(err => console.log(err));
              } else {
                updatedStepRoles.push(role);
              }
            }

            return { ...step, StepRoles: [...updatedStepRoles] };
          });

          setSteps(updatedSteps);
        })
        .catch(err => console.log(err));
    } else {
      let previousWorkflowRoles = workflowRoles;
      let updatedWorkflowRoles = previousWorkflowRoles.filter(p => p.id !== tempId);
      setWorkflowRoles(updatedWorkflowRoles);

      let updatedSteps = steps?.map((step, index) => {
        let initialStepRoles = step?.StepRoles;
        let updatedStepRoles = initialStepRoles?.filter(i => i.RoleID !== roleId);

        return { ...step, StepRoles: [...updatedStepRoles] };
      });

      setSteps(updatedSteps);
    }
  };

  const handleWorkflowStepDeleteClick = (id, tempId) => {
    if (id) {
      workflowStepService
        .remove(id)
        .then(() => {
          let previousWorkflowSteps = steps;

          let updatedWorkflowSteps = previousWorkflowSteps.filter(p => p.ptsWFlowStepID !== id);

          setSteps(updatedWorkflowSteps);
        })
        .catch(err => console.log(err));
    } else {
      let previousWorkflowSteps = steps;

      let updatedWorkflowSteps = previousWorkflowSteps.filter(p => p.id !== tempId);

      setSteps(updatedWorkflowSteps);
    }
  };

  const handleStepSaveClick = () => {
    if (workflowStep?.ptsWFlowStepID) {
      let stepIndex = steps?.findIndex(
        step => step?.ptsWFlowStepID === workflowStep?.ptsWFlowStepID
      );
      steps[stepIndex] = { ...workflowStep };
    } else if (workflowStep?.id) {
      let stepIndex = steps?.findIndex(step => step?.id === workflowStep?.id);

      if (stepIndex === -1) {
        steps.push(workflowStep);
      } else {
        steps[stepIndex] = { ...workflowStep };
      }
    } else {
      steps.push(workflowStep);
    }

    closeStepsDialog();
  };

  const handleWorkflowSaveClick = () => {
    /*Saving the workflow & updated steps values */
    if (workFlowDialogType === 'Add') {
      workflowService
        .create(workflow)
        .then(res => {
          /*Updating the workflows defaultforincident values */
          if (res?.DefaultForIncident === true) {
            let id = res?.ptsWFlowID;

            workflowService
              .find({
                query: {
                  IsDeleted: false,
                  AgencyID: res?.AgencyID,
                },
              })
              .then(res => {
                let allWorkflows = res?.data;

                for (let workflow of allWorkflows) {
                  if (workflow?.ptsWFlowID !== id) {
                    let changedWorkflow = { ...workflow, DefaultForIncident: false };
                    workflowService
                      .patch(workflow?.ptsWFlowID, changedWorkflow)
                      .catch(err => console.log(err));
                  }
                }
              });
          }

          let workflowId = res?.ptsWFlowID;

          /*Saving the Permission Roles */
          workflowRoles.map((role, index) => {
            if (!role.ptsWFlowRoleID) {
              let updatedRole = { ...role, ptsWFlowID: workflowId };
              workflowRoleService
                .create(updatedRole)
                .then(res => console.log(res))
                .catch(err => console.log(err));
            } else {
              let updatedRole = { ...role, ptsWFlowID: workflowId };
              workflowRoleService
                .patch(role.ptsWFlowRoleID, updatedRole)
                .then(res => console.log(res))
                .catch(err => console.log(err));
            }
          });

          /*Saving the Steps & StepRoles */
          steps.map((step, index) => {
            let toSaveStepRole = step?.StepRoles;
            if (!step.ptsWFlowStepID) {
              let updatedStep = { ...step, ptsWFlowID: workflowId };
              workflowStepService
                .create(updatedStep)
                .then(res => {
                  let stepId = res?.ptsWFlowStepID;

                  if (toSaveStepRole) {
                    toSaveStepRole.map((toSaveRole, index) => {
                      if (!toSaveRole?.ptsWFlowStepRoleID) {
                        let updatedStepRole = { ...toSaveRole, ptsWFlowStepID: stepId };
                        workflowStepRolesService
                          .create(updatedStepRole)
                          .then(res => console.log(res))
                          .catch(err => console.log(err));
                      } else {
                        let updatedStepRole = { ...toSaveRole, ptsWFlowStepID: stepId };
                        workflowStepRolesService
                          .patch(updatedStepRole.ptsWFlowStepRoleID, updatedStepRole)
                          .then(res => console.log(res))
                          .catch(err => console.log(err));
                      }
                    });
                  }
                })
                .catch(err => console.log(err));
            } else {
              let updatedStep = { ...step, ptsWFlowID: workflowId };
              workflowStepService
                .patch(updatedStep.ptsWorkflowStepID, updatedStep)
                .then(res => {
                  let stepId = res?.ptsWFlowStepID;

                  if (toSaveStepRole) {
                    toSaveStepRole.map((toSaveRole, index) => {
                      if (!toSaveRole?.ptsWFlowStepRoleID) {
                        let updatedStepRole = { ...toSaveRole, ptsWFlowStepID: stepId };
                        workflowStepRolesService
                          .create(updatedStepRole)
                          .then(res => console.log(res))
                          .catch(err => console.log(err));
                      } else {
                        let updatedStepRole = { ...toSaveRole, ptsWFlowStepID: stepId };
                        workflowStepRolesService
                          .patch(updatedStepRole.ptsWFlowStepRoleID, updatedStepRole)
                          .then(res => console.log(res))
                          .catch(err => console.log(err));
                      }
                    });
                  }
                })
                .catch(err => console.log(err));
            }
          });

          closeWorkflowDialog('reload');
        })
        .catch(err => console.log(err));
    } else {
      workflowService
        .patch(selectedWorkflow.ptsWFlowID, workflow)
        .then(res => {
          /*Updating the workflows defaultforincident values */
          if (res?.DefaultForIncident === true) {
            let id = res?.ptsWFlowID;

            workflowService
              .find({
                query: {
                  IsDeleted: false,
                  AgencyID: res?.AgencyID,
                },
              })
              .then(res => {
                let allWorkflows = res?.data;

                for (let workflow of allWorkflows) {
                  if (workflow?.ptsWFlowID !== id) {
                    let changedWorkflow = { ...workflow, DefaultForIncident: false };
                    workflowService
                      .patch(workflow?.ptsWFlowID, changedWorkflow)
                      .catch(err => console.log(err));
                  }
                }
              });
          }

          /*Saving the Permission Roles */
          workflowRoles.map((role, index) => {
            if (!role.ptsWFlowRoleID) {
              workflowRoleService
                .create(role)
                .then(res => console.log(res))
                .catch(err => console.log(err));
            } else {
              workflowRoleService
                .patch(role.ptsWFlowRoleID, role)
                .then(res => console.log(res))
                .catch(err => console.log(err));
            }
          });

          /*Saving the Steps & StepRoles */
          steps.map((step, index) => {
            let toSaveStepRole = step?.StepRoles;
            if (!step.ptsWFlowStepID) {
              workflowStepService
                .create(step)
                .then(res => {
                  let stepId = res?.ptsWFlowStepID;

                  if (toSaveStepRole) {
                    toSaveStepRole.map((toSaveRole, index) => {
                      if (!toSaveRole?.ptsWFlowStepRoleID) {
                        let updatedStepRole = { ...toSaveRole, ptsWFlowStepID: stepId };
                        workflowStepRolesService
                          .create(updatedStepRole)
                          .then(res => console.log(res))
                          .catch(err => console.log(err));
                      } else {
                        let updatedStepRole = { ...toSaveRole, ptsWFlowStepID: stepId };
                        workflowStepRolesService
                          .patch(updatedStepRole.ptsWFlowStepRoleID, updatedStepRole)
                          .then(res => console.log(res))
                          .catch(err => console.log(err));
                      }
                    });
                  }
                })
                .catch(err => console.log(err));
            } else {
              workflowStepService
                .patch(step.ptsWFlowStepID, step)
                .then(res => {
                  let stepId = res?.ptsWFlowStepID;

                  if (toSaveStepRole) {
                    toSaveStepRole.map((toSaveRole, index) => {
                      if (!toSaveRole?.ptsWFlowStepRoleID) {
                        let updatedStepRole = { ...toSaveRole, ptsWFlowStepID: stepId };
                        workflowStepRolesService
                          .create(updatedStepRole)
                          .then(res => console.log(res))
                          .catch(err => console.log(err));
                      } else {
                        let updatedStepRole = { ...toSaveRole, ptsWFlowStepID: stepId };
                        workflowStepRolesService
                          .patch(updatedStepRole.ptsWFlowStepRoleID, updatedStepRole)
                          .then(res => console.log(res))
                          .catch(err => console.log(err));
                      }
                    });
                  }
                })
                .catch(err => console.log(err));
            }
          });

          closeWorkflowDialog('reload');
        })
        .catch(err => console.log(err));
    }
  };

  const handleSavePermissionsClick = () => {
    /*Updating the permission roles array*/
    if (currentRole?.ptsWFlowRoleID) {
      let roleIndex = workflowRoles?.findIndex(
        role => role?.ptsWFlowRoleID === currentRole?.ptsWFlowRoleID
      );
      workflowRoles[roleIndex] = { ...currentRole };
    } else if (currentRole?.id) {
      let roleIndex = workflowRoles?.findIndex(role => role?.id === currentRole?.id);
      if (roleIndex === -1) {
        workflowRoles.push(currentRole);
      } else {
        workflowRoles[roleIndex] = { ...currentRole };
      }
    } else {
      workflowRoles.push(currentRole);
    }
    let updatedSteps = steps?.map((step, index) => {
      let updatedStepRoles = [];
      let previousStepRoles = step?.StepRoles;
      for (let role of workflowRoles) {
        let foundIndex = previousStepRoles.findIndex(pre => pre.RoleID === role.RoleID);
        if (foundIndex === -1) {
          updatedStepRoles.push({ ...role, ptsWFlowStepID: step.ptsWFlowStepID });
        } else {
          let previousRole = previousStepRoles[foundIndex];
          previousStepRoles[foundIndex] = {
            ...previousRole,
            ...role,
            ptsWFlowStepID: step.ptsWFlowStepID,
          };
        }
      }
      updatedStepRoles = [...updatedStepRoles, ...previousStepRoles];
      return { ...step, StepRoles: updatedStepRoles };
    });
    setSteps(updatedSteps);
    closePermissionsDialog();
  };

  useEffect(() => {
    const agencyId = workflow?.AgencyID ? workflow?.AgencyID : '';
    findSearchGroups(agencyId).then(res => {
      let roleData = res.map((role, index) => {
        return { roleName: role.Label, RoleID: role.ptsGroupID };
      });
      setRoles(roleData);
    });
  }, [workflow?.AgencyID]);

  useEffect(() => {
    let workflowId = selectedWorkflow?.ptsWFlowID;
    let foundWorkflow = allWorkflows?.find(w => w.ptsWFlowID === workflowId);

    if (workFlowDialogType === 'Edit' && workflowId) {
      setWorkflow(foundWorkflow);

      let d = foundWorkflow?.Step.map((r, i) => {
        let foundFetchedStep = fetchedSteps?.data?.find(
          fetched => fetched?.ptsWFlowStepID === r?.ptsWFlowStepID
        );

        let assignRoleNames = foundFetchedStep?.StepRole.map((s, i) => {
          let foundRole = allRoles?.find(r => r.RoleID === s.RoleID);
          return { ...s, roleName: foundRole?.roleName };
        });

        return { ...r, id: i + 1, StepRoles: assignRoleNames };
      });
      d.sort((a, b) => (a.Order > b.Order ? 1 : -1));
      setSteps(d);

      let r = foundWorkflow?.Role.map((role, i) => {
        let foundRole = allRoles.find(r => r.RoleID === role.RoleID);
        return { ...role, roleName: foundRole?.roleName };
      });
      setWorkflowRoles(r);
    } else if (workFlowDialogType === 'Add') {
      setSteps([]);
      setWorkflow({});
      setWorkflowRoles([]);
    }
    setReload(false);
  }, [
    selectedWorkflow?.ptsWFlowID,
    allWorkflows,
    reload,
    workFlowDialogType,
    fetchedSteps,
    allRoles,
  ]);

  const handleDragEnd = result => {
    let tempSteps = [...steps];
    let [selectedStep] = tempSteps.splice(result.source.index, 1);
    tempSteps.splice(result.destination.index, 0, selectedStep);

    let orderValue = 1;
    for (let step of tempSteps) {
      step.Order = orderValue;
      orderValue++;
    }

    setSteps(tempSteps);
  };

  const renderWorkflowDialog = () => {
    return (
      <Dialog
        open={workflowDialog}
        onClose={closeWorkflowDialog}
        classes={{
          paperWidthSm: classes.workflowDialogBox,
        }}>
        <DialogTitle
          className="d-flex justify-content-between align-items-center"
          style={{ background: '#1976d2' }}>
          <span style={{ fontSize: '18px', color: 'white' }}>{workFlowDialogType} Workflow</span>
        </DialogTitle>
        <DialogContent>
          <Grid container justify="center" style={{ padding: '4px 20px 0px 5px' }}>
            <Grid container xs={12} direction="row">
              <Grid item xs={8} className="pr-3">
                <TextField
                  autoComplete="hidden"
                  size="small"
                  id="standard-textfield-workflow-name"
                  label="Workflow Name"
                  placeholder="Workflow Name"
                  variant="outlined"
                  fullWidth
                  multiline
                  defaultValue={workflow?.Name || ''}
                  onBlur={event => handleWorkflowChange(event.target.name, event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  name="Name"
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="default-incident-checkbox"
                        key={workflow?.DefaultForIncident}
                        checked={workflow?.DefaultForIncident}
                        onChange={event =>
                          handleWorkflowChange(event.target.name, event.target.checked)
                        }
                        name="DefaultForIncident"
                      />
                    }
                    label="Default for Incident"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <Autocomplete2
                  label="Agency"
                  className={classes.item}
                  options={agencies}
                  onChange={(e, newValue) =>
                    handleWorkflowChange('AgencyID', newValue?.AgencyID || null)
                  }
                  value={{ AgencyID: workflow?.AgencyID }}
                  getOptionLabel={option => option.AgencyID}
                  getOptionSelected={(option, value) => option.AgencyID === value.AgencyID}
                  renderOption={option => option.AgencyID}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} direction="row" className="mt-2">
              <Grid item xs={5} className="pr-2">
                <div className={clsx(classes.dialogFieldsetWrapperFilter, 'mb-2 mt-2')}>
                  <div className={classes.fieldsetTitleFilter}>Permissions</div>
                  <PerfectScrollbar
                    style={{ maxHeight: '300px' }}
                    options={{ suppressScrollX: true }}>
                    <div className="mt-2">
                      <AddButton
                        tooltipText="Add Permissions"
                        onClick={handleClickAddPermissions}
                      />
                      {/*TODO: Make the Filter dynamic */}
                      <TextField
                        fullWidth
                        label="Filter"
                        variant="outlined"
                        size="small"
                        style={{ width: '60%', float: 'right' }}
                        value={''}
                      />
                    </div>
                    <Table>
                      <TableBody>
                        {workflowRoles &&
                          workflowRoles.map((role, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{role.roleName}</TableCell>
                                <TableCell>
                                  <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                                    <Tooltip title="Edit Permissions">
                                      <IconButton edge="end" aria-label="edit" className="ml-2">
                                        <EditIcon
                                          onClick={() => handleWorkflowRoleEditClick(role)}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete Permissions">
                                      <IconButton edge="end" aria-label="delete" className="ml-1">
                                        <DeleteIcon
                                          onClick={() =>
                                            handleWorkflowRoleDeleteClick(
                                              role.ptsWFlowRoleID,
                                              role.RoleID,
                                              role?.id
                                            )
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </PerfectScrollbar>
                </div>
              </Grid>
              <Grid item xs={7}>
                <div className={clsx(classes.dialogFieldsetWrapperFilter, 'mb-2 mt-2')}>
                  <div className={classes.fieldsetTitleFilter}>Workflow Steps</div>
                  <PerfectScrollbar
                    style={{ maxHeight: '300px' }}
                    options={{ suppressScrollX: true }}>
                    <div className="mt-2">
                      <AddButton tooltipText="Add Steps" onClick={handleClickAddSteps} />
                    </div>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Table>
                        <TableHead>
                          <TableCell>Order</TableCell>
                          <TableCell>Step Name</TableCell>
                          <TableCell>Expiration</TableCell>
                          <TableCell>{''}</TableCell>
                        </TableHead>
                        <Droppable droppableId="steps-table">
                          {provided => (
                            <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                              {steps &&
                                steps?.map((step, index) => (
                                  <Draggable key={step.Name} draggableId={step.Name} index={index}>
                                    {provided => (
                                      <TableRow
                                        key={step.Name}
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}>
                                        <TableCell>{step.Order}</TableCell>
                                        <TableCell {...provided.dragHandleProps}>
                                          {step.Name}
                                        </TableCell>
                                        <TableCell>{step.Expiration}</TableCell>
                                        <TableCell>
                                          <Grid
                                            container
                                            wrap="nowrap"
                                            spacing={2}
                                            justify="flex-end">
                                            <Tooltip title="Edit Step">
                                              <IconButton
                                                edge="end"
                                                aria-label="edit"
                                                className="ml-2">
                                                <EditIcon
                                                  onClick={() => handleWorkflowStepEditClick(step)}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete Step">
                                              <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                className="ml-1">
                                                <DeleteIcon
                                                  onClick={() =>
                                                    handleWorkflowStepDeleteClick(
                                                      step.ptsWFlowStepID,
                                                      step?.id
                                                    )
                                                  }
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </Grid>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </TableBody>
                          )}
                        </Droppable>
                      </Table>
                    </DragDropContext>
                  </PerfectScrollbar>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ padding: 5, margin: '-15px 0px 10px' }}>
                <Button
                  style={{ background: '#1976d2', color: '#fff' }}
                  variant="contained"
                  onClick={handleWorkflowSaveClick}>
                  Save
                </Button>
                <Button
                  onClick={() => closeWorkflowDialog('notReload')}
                  color="primary"
                  style={{ marginRight: 5 }}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const renderStepsDialog = () => {
    return (
      <Dialog
        open={stepsDialog}
        onClose={closeStepsDialog}
        classes={{
          paperWidthSm: classes.dialogBox,
        }}>
        <DialogTitle
          className="d-flex justify-content-between align-items-center"
          style={{ background: '#1976d2' }}>
          <span style={{ fontSize: '18px', color: 'white' }}>
            {workflowStep?.ptsWFlowID ? 'Edit' : 'Add'} Workflow Step
          </span>
        </DialogTitle>
        <DialogContent>
          <Grid container justify="center" style={{ padding: '4px 20px 0px 5px' }}>
            <Grid container xs={12} direction="row">
              <Grid item xs={3} className="mb-2 pr-2" alignItems="center" justifyContent="center">
                <TextField
                  autoComplete="hidden"
                  id="standard-textfield-step-order"
                  fullWidth
                  label="Order"
                  placeholder="Order"
                  variant="outlined"
                  size="small"
                  multiline
                  defaultValue={workflowStep?.Order || ''}
                  onBlur={event => handleStepChange(event.target.name, event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  name="Order"
                />
              </Grid>
              <Grid item xs={6} className="mb-2 pr-2" alignItems="center" justifyContent="center">
                <TextField
                  autoComplete="hidden"
                  id="standard-textfield-step-name"
                  fullWidth
                  label="Step Name"
                  placeholder="Step Name"
                  variant="outlined"
                  size="small"
                  multiline
                  defaultValue={workflowStep?.Name || ''}
                  onBlur={event => handleStepChange(event.target.name, event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  name="Name"
                />
              </Grid>
              <Grid item xs={2} className="mb-2" alignItems="center" justifyContent="center">
                <TextField
                  autoComplete="hidden"
                  id="standard-textfield-step-exp"
                  fullWidth
                  label="Exp"
                  placeholder="Exp"
                  variant="outlined"
                  size="small"
                  defaultValue={workflowStep?.Expiration || ''}
                  onBlur={event => handleStepChange(event.target.name, event.target.value)}
                  InputLabelProps={{ shrink: true }}
                  name="Expiration"
                />
              </Grid>
              <span className="mt-2 ml-2" style={{ fontSize: '15px' }}>
                Days
              </span>
            </Grid>
            <Grid container xs={12}>
              <p style={{ fontSize: '15px' }}>When advancing from this step:</p>
            </Grid>
            <Grid container xs={12} direction="row">
              <Grid item xs={6} className="mb-2 pr-2" alignItems="center" justifyContent="center">
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="ibr-alert-checkbox"
                        key={workflowStep?.AlertForIBRErrors}
                        checked={workflowStep?.AlertForIBRErrors}
                        onChange={event =>
                          handleStepChange(event.target.name, event.target.checked)
                        }
                        name="AlertForIBRErrors"
                      />
                    }
                    label="Alert for IBR errors"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} className="mb-2 " alignItems="center" justifyContent="center">
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="promt-approval-checkbox"
                        key={workflowStep?.PromtForApproval}
                        checked={workflowStep?.PromtForApproval}
                        onChange={event =>
                          handleStepChange(event.target.name, event.target.checked)
                        }
                        name="PromtForApproval"
                      />
                    }
                    label="Prompt for Approval"
                  />
                </FormControl>
              </Grid>
            </Grid>

            <div className={clsx(classes.dialogFieldsetWrapperFilter, 'mb-2 mt-2')}>
              <div className={classes.fieldsetTitleFilter}>Permissions</div>
              <Grid container xs={12} direction="row" className="mt-2">
                <TextField fullWidth label="Filter" variant="outlined" size="small" value={''} />
              </Grid>
              <Grid container xs={12} direction="row" className="mt-2">
                <Table>
                  <TableHead>
                    <TableCell>Role</TableCell>
                    <TableCell>Advance</TableCell>
                    <TableCell>Return</TableCell>
                    <TableCell>Remove</TableCell>
                  </TableHead>
                  <TableBody>
                    {workflowStep?.StepRoles &&
                      workflowStep?.StepRoles?.map((stepRole, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{stepRole?.roleName}</TableCell>
                            <TableCell>
                              <FormControl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      id="step-advance-checkbox"
                                      key={stepRole?.Advance}
                                      checked={stepRole?.Advance}
                                      onChange={event =>
                                        handleStepRoleChange(
                                          event.target.name,
                                          event.target.checked,
                                          stepRole?.RoleID
                                        )
                                      }
                                      name="Advance"
                                    />
                                  }
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      id="step-return-checkbox"
                                      key={stepRole?.Return}
                                      checked={stepRole?.Return}
                                      onChange={event =>
                                        handleStepRoleChange(
                                          event.target.name,
                                          event.target.checked,
                                          stepRole?.RoleID
                                        )
                                      }
                                      name="Return"
                                    />
                                  }
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      id="step-remove-checkbox"
                                      key={stepRole?.Remove}
                                      checked={stepRole?.Remove}
                                      onChange={event =>
                                        handleStepRoleChange(
                                          event.target.name,
                                          event.target.checked,
                                          stepRole?.RoleID
                                        )
                                      }
                                      name="Remove"
                                    />
                                  }
                                />
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Grid>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ padding: 5, margin: '-15px 0px 10px' }}>
                <Button
                  style={{ background: '#1976d2', color: '#fff' }}
                  variant="contained"
                  onClick={handleStepSaveClick}>
                  Save
                </Button>
                <Button onClick={closeStepsDialog} color="primary" style={{ marginRight: 5 }}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const renderPermissionsDialog = () => {
    return (
      <Dialog
        open={permissionsDialog}
        onClose={closePermissionsDialog}
        classes={{
          paperWidthSm: classes.dialogBox,
        }}>
        <DialogTitle
          className="d-flex justify-content-between align-items-center"
          style={{ background: '#1976d2' }}>
          <span style={{ fontSize: '18px', color: 'white' }}>Default Permissions</span>
        </DialogTitle>
        <DialogContent>
          <Grid container justify="center" style={{ padding: '4px 20px 0px 5px' }}>
            <Grid container xs={12}>
              <Autocomplete2
                label="Role-ADD New"
                className={classes.item}
                options={roles}
                onChange={(e, newValue) => setCurrentRole({ ...currentRole, ...newValue })}
                value={currentRole}
                getOptionLabel={option => option.roleName}
                getOptionSelected={(option, value) => option.roleName === value.roleName}
                renderOption={option => option.roleName}
              />
            </Grid>
            <Grid container xs={12} direction="row" className="ml-2" style={{ marginTop: '-20px' }}>
              <Grid item xs={12}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="advance-checkbox"
                        key={currentRole?.Advance}
                        checked={currentRole?.Advance}
                        onChange={event =>
                          handleRoleChange(event.target.name, event.target.checked)
                        }
                        name="Advance"
                      />
                    }
                    label="Advance"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '-20px' }}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="return-checkbox"
                        key={currentRole?.Return}
                        checked={currentRole?.Return}
                        onChange={event =>
                          handleRoleChange(event.target.name, event.target.checked)
                        }
                        name="Return"
                      />
                    }
                    label="Return"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '-20px' }}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="remove-checkbox"
                        key={currentRole?.Remove}
                        checked={currentRole?.Remove}
                        onChange={event =>
                          handleRoleChange(event.target.name, event.target.checked)
                        }
                        name="Remove"
                      />
                    }
                    label="Remove"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ padding: 5, margin: '-15px 0px 10px' }}>
                <Button
                  style={{ background: '#1976d2', color: '#fff' }}
                  variant="contained"
                  onClick={handleSavePermissionsClick}>
                  Save
                </Button>
                <Button onClick={closePermissionsDialog} color="primary" style={{ marginRight: 5 }}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Content>
      {renderWorkflowDialog()}
      {renderPermissionsDialog()}
      {renderStepsDialog()}
    </Content>
  );
}

export default AddEditDialog;
