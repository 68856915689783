import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import { closeAddSecRole } from 'reducers/DialogsReducer';
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { findSecCode, createSecGroup } from 'Settings/Security/SecFunctions';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import { gridStyle } from 'utils/formStyles';
import Autocomplete2 from 'components/Autocomplete2';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';


const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
    margin: 0,
  }
}));

const AddSecRoleDialog = (props) => {
  const classes = useStyles();
  const { Agencies } = props.dictionary;
  const [name, setName] = useState('');
  const [currentType, setCurrentType] = useState(null);
  const [currentAgency, setCurrentAgency] = useState(null);
  const [allTypes, setAllTypes] = useState([]);


  useEffect(() => {
    fetchTypes();
  }, [])


  const fetchTypes = async () => {
    const types = await findSecCode('grouptype');
    setAllTypes(types);
  }


  const close = () => {
    props.closeAddSecRole();
  }

  const save = async () => {
    const data = {
      Label: name,
      Type: currentType?.Code || null,
      AgencyID: currentAgency?.AgencyID || null
    }
    try{
      const result = await createSecGroup(data);
      props.notifyDataUpdate({type: 'add-role', data: result.ptsGroupID});
      props.notify("New Role Created", "success");
      props.closeAddSecRole();
    } catch(err) {
      props.handleError(err, 'Error Creating New Role');
    }
  }

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save} disabled={!name} >
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };


  const renderName = () => {
    return (
      <TextField 
        label="Role Name"
        variant="outlined"
        value={name}
        size="small"
        onChange={(e) => setName(e.target.value)}
        fullWidth
      />
    )
  }


  const renderType = () => {
    const handleChange = (e, newValue) => setCurrentType(newValue);
    return (
      <Autocomplete
        className='mt-3'
        disablePortal
        options={allTypes}
        renderInput={(params) => <TextField {...params} label="Role Type" variant="outlined" />}
        onChange={handleChange}
        getOptionLabel={option => option.Code}
        getOptionSelected={(option, value) => option.Code === value.Code}
        renderOption={option => `${option.Code} ${option.Description ? '- ' + option.Description : ''}`}
        size="small"
        value={currentType}
      />
    )
  }


  const renderAgency = () => {
    const handleChange = (e, newValue) => setCurrentAgency(newValue);
    return (
      <div className='mt-3'>
        <Autocomplete2
          options={Agencies}
          className={classes.item}
          onChange={handleChange}
          value={currentAgency}
          label="Agency"
          getOptionLabel={option => option.AgencyID}
          getOptionSelected={(option, value) => option.AgencyID === value.AgencyID}
          renderOption={option => `${option.AgencyID} ${option.Description ? '- ' + option.Description : ''}`}
        />
      </div>
    )
  }


  return (
    <Dialog onClose={close} title="Add New Role" actions={renderActions()} >
      <div>
        {renderName()}
        {renderType()}
        {renderAgency()}
      </div>
    </Dialog>
  )
}


const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(mapStateToProps, {  closeAddSecRole, handleError, notify, notifyDataUpdate, })(AddSecRoleDialog);