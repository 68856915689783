import React, { useEffect, useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core';

import { XGrid } from '@material-ui/x-grid';
import { displayDate } from 'reducers/TimeReducer';
import TopCard from './TopCard';
import AttatchmentsActions from './AttatchmentsActions';

const useStyles = makeStyles(theme => ({
  gridWrap: {
    width: '100%',
    // boxSizing: 'border-box',
    height: '450px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    minHeight: theme.spacing(6),
    width: '100%',
  },
  filterWrap: {
    flexShrink: 1,
    flexBasis: 400,
  },
  filter: {
    width: '100%',
  },
  action: {
    flexGrow: 0,
    flexShrink: 0,
    width: 'auto',
    marginLeft: theme.spacing(1),
    textAlign: 'right',
  },
}));

const bool = params => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  { field: 'PersonID', headerName: 'Person ID', width: 120 },
  { field: 'FullName', headerName: 'Full Name', width: 200 },
  { field: 'FirstName', headerName: 'First Name', width: 150 },
  { field: 'MiddleName', headerName: 'Middle Name', width: 150 },
  { field: 'LastName', headerName: 'Last Name', width: 150 },
  { field: 'Suffix', headerName: 'Suffix', width: 100 },
  { field: 'NickName', headerName: 'Nick Name', width: 150 },
  { field: 'Age', headerName: 'Age', width: 80 },
  { field: 'Sex', headerName: 'Sex', width: 100 },
  { field: 'OLN', headerName: 'OLN', width: 120 },
  { field: 'SSN', headerName: 'SSN', width: 150 },
  { field: 'Juvenile', headerName: 'Juvenile', width: 150, hide: false, renderCell: bool },
  { field: 'BirthDate', headerName: 'Birth Date', width: 150 },
  { field: 'Deceased', headerName: 'Deceased', width: 150, renderCell: bool },
  {
    field: 'Died',
    headerName: 'Died',
    width: 150,
    valueFormatter: params => displayDate(params.value),
  },
  { field: 'ActiveWarrant', headerName: 'Active Warrant', width: 150, renderCell: bool },
  { field: 'Incarcerated', headerName: 'Incarcerated', width: 150, renderCell: bool },
  { field: 'Officer', headerName: 'Officer', width: 150, renderCell: bool },
  { field: 'PublicOfficial', headerName: 'Public Official', width: 150, renderCell: bool },
  { field: 'ProtectiveOrder', headerName: 'Protective Order', width: 150, renderCell: bool },
  { field: 'SexOffender', headerName: 'Sex Offender', width: 150, renderCell: bool },
  { field: 'Military', headerName: 'Military', width: 150, renderCell: bool },
  { field: 'ConcealedWeaponPermit', headerName: 'Concealed Weapon', width: 150, renderCell: bool },
  { field: 'SeniorCitizen', headerName: 'Senior Citizen', width: 150, renderCell: bool },
  { field: 'Dangerous', headerName: 'Dangerous', width: 150, renderCell: bool },
  { field: 'Gang', headerName: 'Gang', width: 150, renderCell: bool },
  { field: 'Ethnicity', headerName: 'Ethnicity', width: 150 },
  { field: 'EyeColor', headerName: 'Eye Color', width: 150 },
  { field: 'HairColor', headerName: 'Hair Color', width: 150 },
  { field: 'Race', headerName: 'Race', width: 150 },
  { field: 'Height', headerName: 'Height', width: 100 },
  { field: 'Weight', headerName: 'Weight', width: 100 },
  { field: 'Created', headerName: 'Created', width: 150 },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  { field: 'Updated', headerName: 'Updated', width: 150 },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
];
const PersonTab = props => {
  const { personList, ptsSOPID } = props;

  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState(null);

  useEffect(() => {
    setRows(personList);
  }, [personList]);
  return (
    <div>
      <TopCard parent="Person" ptsSOPID={ptsSOPID} />
      <h5>Person List</h5>
      <AttatchmentsActions selection={selection} ptsSOPID={ptsSOPID} ParentType={'Person'} />
      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={rows}
          rowHeight={38}
          disableMultipleSelection
          getRowId={row => row.ptsPersonID}
          onSelectionModelChange={newSelection => {
            setSelection(parseInt(newSelection.selectionModel[0]));
          }}
        />
      </div>
    </div>
  );
};

export default PersonTab;
