import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import XGrid3 from 'components/XGrid3';
import Content from 'Settings/Content';
import { getService } from 'reducers/service';
import QuickFilterSearch from 'components/QuickFilterSearch';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import Tooltip from 'components/Tooltip';
import { notify } from 'reducers/NotifierReducer';

import { showEditAgency } from 'reducers/DialogsReducer';
import options from 'components/IconsAgency';
const useStyles = makeStyles(theme => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
}));

const iconRender = params => {
  const Icon = options[params.value];
  return Icon ? <Icon /> : ' ';
};
const defaultColumns = [
  { field: 'AgencyID', headerName: 'Agency ID' },
  { field: 'Description', headerName: 'Description' },
  { field: 'Icon', headerName: 'Icon', width: 150, renderCell: iconRender },

  { field: 'PlaceID', headerName: 'Place ID' },
  { field: 'PlaceName', headerName: 'Place Name' },
  { field: 'Police', headerName: 'Police', format: 'bool' },
  { field: 'Fire', headerName: 'Fire', format: 'bool' },
  { field: 'EMS', headerName: 'EMS', format: 'bool' },
  {
    field: 'IsDeleted',
    format: 'bool',
  },
  {
    field: 'Deleted',
    format: 'date',
  },
  { field: 'DeletedBy', headerName: 'Deleted By' },
  { field: 'DeletedID', headerName: 'Deleted ID' },
];

function Agency(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const service = getService('cad-search-agencies');
  const generalService = getService('ptsagencies');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [agencyColumns, setAgencyColumns] = useState(defaultColumns);
  const [printFlag, setPrintFlag] = useState(false);

  useEffect(() => {
    getAgencyData();
  }, []);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === 'agencies') getAgencyData();
  }, [dataUpdate]);

  const getAgencyData = async () => {
    const data = await service.find({
      query: {
        originSettings: true,
      },
    });

    setRows(processData(data));
  };

  const processData = data => {
    return data.map(row => {
      return {
        ...row,
        id: row.AgencyID,
      };
    });
  };
  const onClickDelete = async () => {
    if (!window.confirm(`Are you sure you want to delete   "${selection}" Agency?`)) return;
    const removeAgency = await generalService.remove(selection);

    const newRows = rows.filter(el => el.AgencyID !== removeAgency.AgencyID);
    setRows(newRows);
    props.notify('Deleted', 'warning');
    setSelection([]);
  };
  return (
    <Content>
      <div className="m-4">
        <h5>Agencies</h5>
        <div className={classes.filterWrap}>
          <div className={classes.filterWrap}>
            <QuickFilterSearch
              rows={rows}
              columns={agencyColumns}
              setFilteredRows={setFilteredRows}
            />
          </div>
          <div className={classes.actions}>
            <Tooltip title="Add" className="ml-2">
              <span>
                <Fab
                  size="small"
                  color="secondary"
                  onClick={() => props.showEditAgency({ mode: 'add', data: null })}>
                  <AddIcon />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title="Edit" className="ml-2">
              <span>
                <Fab
                  size="small"
                  color="secondary"
                  onClick={() => props.showEditAgency({ data: selection, mode: 'edit' })}
                  disabled={!selection}>
                  <EditIcon />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title="Delete" className="ml-2">
              <span>
                <Fab size="small" color="secondary" onClick={onClickDelete} disabled={!selection}>
                  <DeleteIcon />
                </Fab>
              </span>
            </Tooltip>
          </div>
        </div>
        <XGrid3
          columns={agencyColumns}
          setColumns={setAgencyColumns}
          rows={filteredRows}
          setSelection={setSelection}
          printFlag={printFlag}
          refresh={getAgencyData}
          xgridHeight={200}
        />
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  handleError,
  showEditAgency,
  notify,
})(Agency);
