import { Grid, TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { XGrid } from '@material-ui/x-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import Content from '../ContentForLookupCodes';
import { getService } from 'reducers/service';
import LookupPage from './Menu';
import LookUpCodesAddEditDialog from './../../Dialogs/LookUpCodes/AddEditDialog';
import settings from 'config/settings';
import { displayDate } from 'reducers/TimeReducer';

import {
  partyRelationshipColumns,
  codeCitiesColumns,
  commonColumns,
  codeDispositionColumns,
  codeZonesColumns,
  codeUnitActionColumns,
  codeVehicleMakeModelsColumns,
  codeZonesCols,
  codeTagTypesColumns,
  renderStatutesColumn,
  codeIncidentTypesColumns,
  CallMethodsColumns,
} from './CodeTableColumns';

import Spinner from 'components/Spinner';
import services from './serviceNames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import QuickFilter from 'components/QuickFilter';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  list: {
    width: '100%',
    height: `calc(100vh - 190px)`,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  searchBar: {
    padding: theme.spacing(1, 0, 2),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '15px',
  },
  actions: {
    width: '100%',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  parentFilter: {
    minWidth: '200px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  pageWrap: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.colors.paper,
  },
  menu: {
    height: `calc(100vh - ${settings.mainNavHeight}px)`,
    overflowY: 'scroll',
  },
  content: {
    flex: 1,
  },
}));
const tagTypeOptions = ['Incident', 'Person'];
function LookupCodes() {
  const [columns, setColumns] = useState(commonColumns);
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [item, setItem] = useState('');
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('');
  const [editableRow, setEditableRow] = useState({});
  const [selection, setSelection] = useState([]);
  const [currentService, setCurrentService] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const dictionary = useSelector(state => state.dictionary);
  const handleSelectChange = ({ selectionModel }) => setSelection(selectionModel);
  const [filteredRows, setFilteredRows] = useState([]);
  const [parentFilter, setParentFilter] = useState(null);

  const handleClickOnListItem = async item => {
    setCurrentService(item.service);
    getColumns(item.service);
    await getLookUpCodes(item?.service);
    setItem(item);
  };

  const handleClose = async () => {
    await getLookUpCodes(item?.service);
    setMode('');
    setOpen(false);
  };

  const getLookUpCodes = async serviceName => {
    try {
      setShowSpinner(true);
      const service = getService(serviceName);
      const codes = await service.find({ query: { IsDeleted: false, $limit: 1000 } });
      const data = getData(serviceName, codes);

      setRows(data);
      setShowSpinner(false);
    } catch (error) {
      console.log('error ', error);
      setShowSpinner(false);
    }
  };
  const onClickAdd = () => {
    setMode('add');
    setEditableRow({});
    setOpen(true);
  };
  const onClickEdit = () => {
    const obj = getEditableObject();
    setEditableRow(obj);
    setMode('edit');
    setOpen(true);
  };

  /* Helper Functions -> start */
  const getColumns = serviceName => {
    switch (serviceName) {
      case 'codepartyrelationship':
        setColumns(partyRelationshipColumns);
        break;
      case 'codecities':
        setColumns(codeCitiesColumns);
        break;
      case 'code-dispositions':
        setColumns(codeDispositionColumns);
        break;
      case 'codezonetypes':
        setColumns(codeZonesColumns);
        break;
      case services.UNIT_ACTIONS:
        setColumns(codeUnitActionColumns);
        break;
      case 'code-vehicle-make-models':
        setColumns(codeVehicleMakeModelsColumns);
        break;
      case 'codezones':
        setColumns(codeZonesCols);
        break;
      case 'codetagtypes':
        setColumns(codeTagTypesColumns);
        break;
      case 'settings-ptsStatutes':
        setColumns(renderStatutesColumn);
        break;
      case 'codeincidenttypes':
        setColumns(codeIncidentTypesColumns);
        break;
      case 'codecadmethods':
        setColumns(CallMethodsColumns);
        break;
      default:
        setColumns(commonColumns);
        break;
    }
  };
  const getData = (serviceName, codes) => {
    let data;
    switch (serviceName) {
      case 'codecities':
        data = codes.data?.map(el => ({ ...el, id: el.ptsCityID }));
        break;
      case 'code-dispositions':
        data = codes.data?.map(el => ({
          ...el,
          id: JSON.stringify({ Code: el.Code, AgencyID: el.AgencyID }),
        }));
        break;
      case 'codezonetypes':
        data = codes.data?.map(el => ({ ...el, id: el.AgencyID }));
        break;
      case 'code-vehicle-make-models':
        data = codes.data?.map(el => ({
          ...el,
          id: JSON.stringify({ MakeCode: el.MakeCode, ModelCode: el.ModelCode }),
        }));
        break;
      case 'codezones':
        data = codes.data?.map(el => ({ ...el, id: el.ZoneCode }));
        break;
      case services.UNIT_ACTIONS:
        data = codes?.map(el => ({ ...el, id: el.Code }));
        break;
      case 'settings-ptsStatutes':
        data = codes?.map(el => ({ ...el, id: el.ptsStatuteID }));
        break;
      default:
        data = codes.data?.map(el => ({ ...el, id: el.Code }));
    }
    return data;
  };
  const getEditableObject = () => {
    let obj;
    switch (currentService) {
      case 'code-dispositions':
        // eslint-disable-next-line no-case-declarations
        const paramsDisp = JSON.parse(selection[0]);
        obj = rows.find(
          row => row.Code === paramsDisp.Code && row.AgencyID === paramsDisp.AgencyID
        );
        break;
      case 'codezonetypes':
        obj = rows.find(row => row.AgencyID === selection[0]);
        break;
      case 'codecities':
        obj = rows.find(row => JSON.stringify(row.ptsCityID) === selection[0]);
        break;
      case 'code-vehicle-make-models':
        // eslint-disable-next-line no-case-declarations
        const params = JSON.parse(selection[0]);
        obj = rows.find(
          row => row.MakeCode === params.MakeCode && row.ModelCode === params.ModelCode
        );
        break;
      case 'codezones':
        obj = rows.find(row => row.ZoneCode === selection[0]);
        break;
      case 'settings-ptsStatutes':
        obj = rows.find(row => row.ptsStatuteID == selection[0]);

        break;
      default:
        obj = rows.find(row => row.Code === selection[0]);
        break;
    }
    return obj;
  };
  /* Helper Functions -> end */

  const onClickDelete = async () => {
    const service = getService(item?.service);
    let removeCode;
    let newRows;
    switch (currentService) {
      case 'code-dispositions':
        // eslint-disable-next-line no-case-declarations
        const paramsDisp = JSON.parse(selection[0]);
        removeCode = await service.remove({ Code: paramsDisp.Code, AgencyID: paramsDisp.AgencyID });
        newRows = rows.filter(
          el => el.Code !== paramsDisp.Code && el.AgencyID !== paramsDisp.AgencyID
        );
        break;
      case 'codezonetypes':
        removeCode = await service.remove(selection[0]);
        newRows = rows.filter(el => el.AgencyID !== removeCode.AgencyID);
        break;
      case 'code-vehicle-make-models':
        // eslint-disable-next-line no-case-declarations
        const params = JSON.parse(selection[0]);
        removeCode = await service.remove({
          MakeCode: params.MakeCode,
          ModelCode: params.ModelCode,
        });
        newRows = rows.filter(
          el => el.MakeCode !== params.MakeCode && el.ModelCode !== params.ModelCode
        );
        break;
      case 'settings-ptsStatutes':
        removeCode = await service.remove(selection[0]);
        newRows = rows.filter(el => el.ptsStatuteID !== removeCode.ptsStatuteID);
        break;
      default:
        removeCode = await service.remove(selection[0]);
        newRows = rows.filter(el => el.Code !== removeCode.Code);
        break;
    }
    setRows(newRows);
  };
  const renderActions = () => {
    const disabled = selection.length === 0;
    //const selected = rows.find(a => a.id === selection[0]);
    //const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <Fab size="small" color="secondary" onClick={onClickAdd}>
          <AddIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={onClickEdit} disabled={disabled}>
          <EditIcon />
        </Fab>
        <Fab size="small" color="secondary" onClick={onClickDelete} disabled={disabled}>
          <DeleteIcon />
        </Fab>
      </div>
    );
  };

  return (
    <Content>
      <div className={classes.pageWrap}>
        <Grid container style={{ padding: 10 }} spacing={2}>
          <Grid item xs={2}>
            <div className={classes.menu}>
              <LookupPage handleClickOnListItem={handleClickOnListItem} />
            </div>
          </Grid>
          <Grid item xs={10}>
            {Object.keys(item).length ? (
              <div className={classes.content}>
                <div className={classes.searchBar}>
                  <QuickFilter
                    rows={rows}
                    columns={columns}
                    setFilteredRows={setFilteredRows}
                    // parentFilter={parentFilter}
                  />
                  {currentService === 'codetagtypes' && (
                    <Autocomplete
                      options={tagTypeOptions}
                      value={parentFilter}
                      size="small"
                      className={classes.parentFilter}
                      renderInput={params => (
                        <TextField {...params} label="Parent Type" variant="outlined" />
                      )}
                      onChange={(event, selection) => setParentFilter(selection)}
                    />
                  )}
                  {renderActions()}
                </div>
                {showSpinner && <Spinner />}
                {!showSpinner && rows?.length ? (
                  <div className={classes.list}>
                    <XGrid
                      // autoPageSize
                      componentsProps={{
                        panel: {
                          style: {
                            top: '-120px !important',
                          },
                        },
                      }}
                      columns={columns}
                      rows={filteredRows}
                      pageSize={10}
                      onSelectionModelChange={handleSelectChange}
                      //disableSelectionOnClick={view === 'edit'}
                      disableMultipleSelection
                      // hideFooter
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    No Data Available
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                Nothing to Display
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <LookUpCodesAddEditDialog
        open={open}
        closeDialog={handleClose}
        mode={mode}
        data={editableRow}
        dictionary={dictionary}
        table={item.table}
        currentService={item?.service}
      />
    </Content>
  );
}

export default LookupCodes;
