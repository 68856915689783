import { Button, Checkbox, FormControlLabel, TextField, makeStyles } from '@material-ui/core';
import FormDivider from 'components/FormDivider';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import formStyles, { ColCard, Fills, Row, RowInner } from 'utils/formStyles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { notify } from 'reducers/NotifierReducer';
import { getService } from 'reducers/service';
const useStyles = makeStyles(theme => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
}));

export const AgencyMobile = props => {
  const classes = useStyles();
  const { wsClient, agencyId } = props;
  const service = getService('cdr');
  const userName = useSelector(state => state.user.userData.user.Username);
  const [enable, setEnable] = useState(false);
  const [exist, setIsExist] = useState(false);
  useEffect(() => {
    const search = async () => {
      service.timeout = 20000;
      try {
        const data = await service.find({
          query: { Path: 'AgencyBasedUnitSetting', CDRKey: agencyId },
        });
        if (data.length > 0) {
          setIsExist(true);
          setEnable(data[0].ValueBoolean);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (wsClient.websocket) {
      search();
    }
  }, [wsClient]);
  const save = async () => {
    props.showSpinner();
    try {
      //   await addEventNote(text, ptsEventID);
      if (exist) {
        const edited = await service.patch(
          null,
          {
            CDRKey: agencyId,
            Path: 'AgencyBasedUnitSetting',
            ValueBoolean: enable,
          },
          {
            query: {
              originSettings: true,
            },
          }
        );

        props.notify('Updated successfully', 'success');
      } else {
        const addedData = await service.create({
          CDRKey: agencyId,
          ValueBoolean: enable,
          Path: 'AgencyBasedUnitSetting',
          CreatedBy: userName,
          UpdatedBy: userName,
        });
        props.notify('Created', 'success');
      }
    } catch (err) {
      props.handleError(err, 'Error, cannot not saved.', err);
    }
    props.hideSpinner();
  };

  const renderActions = () => {
    return (
      <div className="text-right pb-3">
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon className="mr-2"/> Save
        </Button>
      </div>
    );
  };
  return (
    <div className={classes.wrap}>
      {renderActions()}
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Cad Mobile Settings" />
          <RowInner>
            <TextField
              id="agency"
              label="Agency ID"
              variant="outlined"
              value={agencyId || ''}
              size="small"
              disabled
              className="mr-2"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={enable}
                  onChange={e => {
                    setEnable(e.target.checked);
                  }}
                />
              }
              label="Select Unit On Mobile Login"
            />
            <Fills className={classes.item} />
          </RowInner>
        </ColCard>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
});

const mapDispatchToProps = {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyMobile);
