import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import cslx from 'clsx';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) => ({
  wrap: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  dialog: {
    minWidth: 350,
    paddingLeft: 0,
    paddingRight: 0,
    '& p': {
      marginBottom: '0.5em',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.card.bg,
  },
  content: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
  },
  actions: {
    '& button': {
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1310,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog(props) {
  const classes = useStyles();
  const {
    open = true,
    actions,
    children,
    title = 'Computer Aided Dispatch',
    draggable = true,
    toolbar = true,
    maxWidth = 'md', // lg, md, sm, xl, xs, false
    scroll = 'paper',
    className,
    height = 'auto',
    fullScreen = false,
    onClose,
  } = props;

  return (
    <>
      {/* <IconButton className={classes.close} onClick={onClose} size="small">
        <CloseIcon />
      </IconButton> */}
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        maxWidth={maxWidth}
        scroll={scroll}
        fullScreen={fullScreen}
        className={cslx(classes.wrap, className)}>
        {draggable && toolbar && (
          <DialogTitle className={classes.title} disableTypography id="draggable-dialog-title">
            {title}
          </DialogTitle>
        )}
        {!draggable && (
          <DialogTitle className={classes.title} disableTypography>
            {title}
          </DialogTitle>
        )}
        <DialogContent dividers={true} className={classes.dialog} style={{ height }}>
          <div className={classes.content}>{children}</div>
        </DialogContent>
        {Boolean(actions) && <DialogActions className={classes.actions}>{actions}</DialogActions>}
        {Boolean(onClose) && (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </Dialog>
    </>
  );
}
