import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { notify } from '../../reducers/NotifierReducer';
import TextField from '@material-ui/core/TextField';

import { 
  testEmail, testSMS, savePagingSettings, getPagingSettings
} from '../../reducers/PagingReducer';
import { validateEmail } from '../../utils/functions';

function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 8px`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& h4': {
      marginBottom: theme.spacing(2),
    },
  },
  table: {
    minWidth: 750,
    '& td': {
      paddingTop: 2,
      paddingBottom: 2,
    },
  },
  actions: {
    textAlign: 'left',
    marginTop: 30,
    '& button': {
      '& svg': {
        marginRight: theme.spacing(1),
      }
    },
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    maxWidth: 400,
  },
  emailTest: {
    margin: `0 -${theme.spacing(0.5)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    maxWidth: 400,
    justifyContent: 'space-between',
    '& button': {
      marginBottom: theme.spacing(1),
    },
  },
  radioGroup: {
    width: '100%',
  },
  textField: {
    ...getFormStyle(200, 300),
  },
  testField: {
    ...getFormStyle(200, 300),
  },
  w100pr: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    // margin: `-${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
  },
}));

function SMTPSettings(props) {
  const classes = useStyles();
  const [sendgridFrom, setSendgridFrom] = useState('');
  const [sendgridKey, setSendgridKey] = useState('');
  const [emailTo, setEmailTo] = useState('');
  const [twilioSid, setTwilioSid] = useState('');
  const [twilioKey, setTwilioKey] = useState('');
  const [twilioMsgSSid, setTwilioMsgSSid] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [smtpValid, setSmtpValid] = useState(false);

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    validateSMTP();
    // eslint-disable-next-line
  }, [sendgridFrom, sendgridKey, emailTo]);

  const getSettings = async () => {
    props.showSpinner();
    try {
      const settings = await getPagingSettings();
      const { sendgridKey, sendgridFrom, twilioSid, twilioKey, twilioMsgSSid } = settings;
      setSendgridFrom(sendgridFrom);
      setSendgridKey(sendgridKey);
      setTwilioKey(twilioKey);
      setTwilioSid(twilioSid);
      setTwilioMsgSSid(twilioMsgSSid);
    } catch (err) {
      props.handleError(err, 'Error, cannot load settings.');
    }
    props.hideSpinner();
  }

  const validateSMTP = () => {
    let valid = true;
    if (!sendgridFrom || !sendgridKey || !emailTo) valid = false;
    if (sendgridFrom && !validateEmail(sendgridFrom)) valid = false;
    if (emailTo && !validateEmail(emailTo)) valid = false;
    valid !== smtpValid && setSmtpValid(valid);
  }
  
  const save = async () => {
    const data = {
      sendgridFrom,
      sendgridKey,
      twilioKey,
      twilioSid,
      twilioMsgSSid,
    }
    props.showSpinner();
    try {
      await savePagingSettings(data);
      props.notify('Settings saved', 'success');
    } catch (err) {
      props.handleError(err, 'Error, settings not saved');
    }
    props.hideSpinner();
  }

  const onTestEmail = async () => {
    const mail = { 
      sendgridFrom,
      sendgridKey,
      Subject: 'E-mail test from CAD app',
      Body: 'Your CAD email service has been setup correctly.',
      emailTo,
    };
    props.showSpinner();
    try {
      await testEmail(mail);
      props.notify('E-mail has been sent, please check you mailbox.');
    } catch (err) {
      props.notify('Error, e-mail not sent', 'error');
      console.log(err);
    }
    props.hideSpinner();
  }

  const onTestSMS = async () => {
    const mail = { 
      twilioKey,
      twilioSid,
      twilioMsgSSid,
      twilioTo: phoneNo,
      Body: 'Your CAD SMS service has been setup correctly.',
    };
    props.showSpinner();
    try {
      await testSMS(mail);
      props.notify('E-mail has been sent, please check you mailbox.');
    } catch (err) {
      props.notify('Error, e-mail not sent', 'error');
      console.log(err);
    }
    props.hideSpinner();
  }

  const renderSendgridSettings = () => {
    return (
      <div className={classes.form}>
        <TextField
          className={classes.w100pr}
          label="E-mail From"
          value={sendgridFrom}
          onChange={ev => setSendgridFrom(ev.target.value)}
          variant="outlined"
          size="small"
        />
        <TextField
          className={classes.w100pr}
          label="Sendgrid key"
          value={sendgridKey}
          onChange={ev => setSendgridKey(ev.target.value)}
          variant="outlined"
          size="small"
          type="password"
          inputProps={{
            autoComplete: 'new-password',
            form: { autoComplete: 'off' },
          }}
        />
      </div>
    );
  }
 
  const renderTwilioSettings = () => {
    return (
      <div className={classes.form}>
        <TextField
          className={classes.w100pr}
          label="Message Service SID"
          value={twilioMsgSSid}
          onChange={ev => setTwilioMsgSSid(ev.target.value)}
          variant="outlined"
          size="small"
        />
        <TextField
          className={classes.w100pr}
          label="Account SID"
          value={twilioSid}
          onChange={ev => setTwilioSid(ev.target.value)}
          variant="outlined"
          size="small"
        />
        <TextField
          className={classes.w100pr}
          label="Auth Token"
          value={twilioKey}
          onChange={ev => setTwilioKey(ev.target.value)}
          variant="outlined"
          size="small"
          type="password"
          inputProps={{
            autoComplete: 'new-password',
            form: { autoComplete: 'off' },
          }}
        />
      </div>
    );
  }

  const renderEmailTest = () => {
    return (
      <div className={classes.emailTest}> 
        <TextField
          className={classes.testField}
          label="E-mail"
          value={emailTo}
          onChange={ev => setEmailTo(ev.target.value)}
          variant="outlined"
          size="small"
        />
        <div className={classes.btnWrap}>
          <Button 
            variant="contained"
            color="primary"
            onClick={onTestEmail}
            disabled={!smtpValid}
          >
            Test
          </Button>
        </div>
      </div>
    );
  }

  const renderSMSTest = () => {
    return (
      <div className={classes.emailTest}>
        <TextField
          className={classes.testField}
          label="+1 xxx xxx xxx xxx"
          value={phoneNo}
          onChange={ev => setPhoneNo(ev.target.value)}
          variant="outlined"
          size="small"
        />
        <div className={classes.btnWrap}>
          <Button
            variant="contained"
            color="primary"
            onClick={onTestSMS}
            disabled={!twilioSid || !twilioKey || !twilioMsgSSid || !phoneNo}
          >
            Test
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <h4>E-mail Settings</h4>
      <h6>Sendgrid</h6>
      {renderSendgridSettings()}
      <hr />
      <h4>SMS Settings</h4>
      <h6>Twilio</h6>
      {renderTwilioSettings()}
      <hr />
      <h6>Test E-mail Service</h6>
      {renderEmailTest()}
      <h6>Test SMS Service</h6>
      {renderSMSTest()}
      <div className={classes.actions}>
        <Button 
          color="primary"
          variant="contained"
          onClick={save}
        ><SaveIcon /> Save</Button>
      </div>
    </div>
  );
}

export default connect(null, { 
  showSpinner, hideSpinner, handleError, notify
})(SMTPSettings);
