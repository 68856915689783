import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from 'Settings/Content';

import { getService } from 'reducers/service';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';

import { gridStyle } from 'utils/formStyles';
import { XGrid } from '@material-ui/x-grid';
import { Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { showCitationCourt } from 'reducers/DialogsReducer';
import QuickFilter from 'components/QuickFilter';
const useStyles = makeStyles(theme => ({
  item: {
    ...gridStyle(200, 800),
  },
  list: {
    width: '100%',
    height: 500,
  },
  wrap: {
    padding: theme.spacing(2),
    '& > h5': {
      marginBottom: 20,
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: `calc(100vh - 240px)`,
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function CitationCourt(props) {
  const { wsClient, addCitationCourt } = props;
  const classes = useStyles();
  const service = getService('citation-court');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);

  const [mode, setMode] = useState('');
  const [editableRow, setEditableRow] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    const search = async () => {
      service.timeout = 20000;
      try {
        const formDetails = await service.find({
          query: {
            $limit: 1000,
          },
        });
        // console.log('formDetails', formDetails);
        const newList = formDetails.data.map(item => {
          return { ...item, id: item.SQLSysID };
        });
        setRows(newList);
      } catch (error) {
        console.log(error);
      } finally {
        setLoaded(true);
      }
    };
    if (addCitationCourt == null) {
      search();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient.websocket, addCitationCourt]);
  const columnsItems = [
    { field: 'Court_header', headerName: 'Court Header', width: 150 },

    { field: 'Court_Name', headerName: 'Court Name', width: 150 },
    { field: 'Bank_Code', headerName: 'Bank Code', width: 150 },
    { field: 'Street_Address', headerName: 'Street Address', width: 150 },
    { field: 'Mailing_Address', headerName: 'Mailing Address', width: 150 },
    { field: 'City', headerName: 'City', width: 150 },
    { field: 'State', headerName: 'State', width: 150 },
    { field: 'Zip_Code', headerName: 'Zip Code', width: 150 },
    { field: 'Area_Code', headerName: 'Area Code', width: 150 },
    { field: 'Phone_Number', headerName: 'Phone Number', width: 150 },
    { field: 'NextDateTime', headerName: 'Next Date Time', format: 'date', width: 150 },
    { field: 'Court_type', headerName: 'Court Type', width: 150 },
    { field: 'Law_Code_name', headerName: 'Law Code Name', width: 150 },
    { field: 'court_state', headerName: 'Court State', width: 150 },
    { field: 'court_location', headerName: 'Court Location', width: 150 },
    { field: 'DefaultDays', headerName: 'Default Days', width: 150 },
    { field: 'Operator', headerName: 'Operator', width: 150 },
    { field: 'OperatorID', headerName: 'Operator ID', width: 150 },
    { field: 'UpdateDateTime', headerName: 'Updated Date', format: 'date', width: 150 },

    { field: 'Notes', headerName: 'Notes', width: 150 },
  ];
  const handleSelectChange = ({ selectionModel }) => {
    setSelection(selectionModel);
  };

  const onClickAdd = () => {
    dispatch(showCitationCourt({ type: 'add' }));
    setMode('add');
    setOpen(true);
  };
  const onClickEdit = () => {
    const obj = rows.find(row => row.SQLSysID == selection[0]);
    // console.log('obj', obj, selection);
    dispatch(showCitationCourt({ type: 'edit', info: obj }));
    setMode('edit');
    setOpen(true);
  };
  const onClickDelete = async () => {
    const removeAgency = await service.remove(selection[0]);
    const newRows = rows.filter(el => el.SQLSysID !== removeAgency.SQLSysID);
    setRows(newRows);
    props.notify('Deleted', 'warning');
    setSelection([]);
  };
  const renderActions = () => {
    const disabled = selection.length === 0;
    const selected = rows.find(a => a.id === selection[0]);
    //const dltDisabled = selected?.Icon === 0;
    return (
      <div className={classes.actions}>
        <div>
          <QuickFilter rows={rows} columns={columnsItems} setFilteredRows={setFilteredRows} />
        </div>
        <div>
          <Fab size="small" color="secondary" onClick={onClickAdd}>
            <AddIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickEdit} disabled={disabled}>
            <EditIcon />
          </Fab>
          <Fab size="small" color="secondary" onClick={onClickDelete} disabled={disabled}>
            <DeleteIcon />
          </Fab>
        </div>
      </div>
    );
  };
  return (
    <Content>
      <div className="m-4">
        <h4>Citation Court</h4>
        {renderActions()}
        <div className={classes.gridWrap}>
          <XGrid
            columns={columnsItems}
            rows={filteredRows}
            loading={!loaded}
            rowHeight={38}
            getRowId={row => row.SQLSysID}
            // checkboxSelection
            onSelectionModelChange={handleSelectChange}
          />
        </div>
      </div>
    </Content>
  );
}

const mapStateToProps = state => {
  return {
    wsClient: state.websocket,
    addCitationCourt: state.dialogs.addCitationCourt,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(CitationCourt);
