import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { setMenuState } from '../reducers/MenuReducer';
import { NavLink } from 'react-router-dom';
import mainMenu from '../config/leftMenuConfig';

const useStyles = makeStyles(theme => ({
  menu: {
    width: '100%',
    '& hr': {
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
    },
  },
  link: {
    '& > span': {
      fontWeight: 600,
    },
  },
  hasSubmenu: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.colors.grey5,
    },
  },
  submenu: {
    '& > $link': {
      '& span': {
        fontWeight: 400,
        paddingLeft: 10,
        lineHeight: 1,
      },
    },
    '& > $hasSubmenu': {
      '& span': {
        fontWeight: 500,
        paddingLeft: 10,
        lineHeight: 1,
      },
    },
    '& > $submenu': {
      '& > $link': {
        '& span': {
          fontWeight: 600,
          paddingLeft: 20,
          lineHeight: 1,
          fontSize: '0.9em',
        },
      },
    },
  },
  active: {
    color: theme.palette.secondary.main,
  },
}));

function parseMenu(menuDef) {
  let id = 0;
  const state = {};

  const process = menu => {
    if (!menu) return { warning: 'menu not exists' };
    const items = [];
    menu.forEach(i => {
      const key = id++;
      const item = { key, ...i };
      if (item.type === 'menu') {
        item.submenu = process(item.submenu);
        state[key] = false;
      }
      items.push(item);
    });
    return items;
  };

  const items = process(menuDef);
  return { menu: items, state };
}

const { menu, state } = parseMenu(mainMenu);

function SettingsPage(props) {
  const classes = useStyles();
  const { menuState, setMenuState } = props;

  useEffect(() => {
    if (!menuState) setMenuState(state);
    // eslint-disable-next-line
  }, []);

  if (!menuState) return '';

  const renderItem = item => {
    switch (item.type) {
      case 'link':
        return renderLink(item);
      case 'divider':
        return renderDivider(item);
      case 'menu':
        return renderMenu(item);
      default:
        return '';
    }
  };

  const renderLink = item => {
    const { key, link, label } = item;
    return (
      <ListItem
        component={NavLink}
        button
        to={`/${link}`}
        key={key}
        className={classes.link}
        activeClassName={classes.active}>
        <ListItemText primary={label} className={classes.link} />
      </ListItem>
    );
  };

  const renderMenu = item => {
    const { key, submenu, label } = item;
    const open = menuState[key];
    return (
      <Fragment key={key}>
        <ListItem onClick={toggleItem(key)} className={classes.hasSubmenu}>
          <ListItemText primary={label} className={classes.link} />
          {open && <ExpandLess />}
          {!open && <ExpandMore />}
        </ListItem>
        <div style={{ display: open ? 'block' : 'none' }} className={classes.submenu}>
          {submenu.map(renderItem)}
        </div>
      </Fragment>
    );
  };

  const renderDivider = item => <hr key={item.key} />;

  const toggleItem = key => () => {
    const state = { ...menuState };
    const val = !state[key];
    state[key] = val;
    props.setMenuState(state);
  };

  return (
    <div className={classes.menu}>
      <List component="nav" aria-labelledby="nested-list-subheader" dense className={classes.root}>
        {menu.map(renderItem)}
      </List>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    menuState: state.menu,
  };
};

export default connect(mapStateToProps, { setMenuState })(SettingsPage);
